import { Component, OnInit, ViewChild } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { RedData } from '../../../red-data/models/red-data.model';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-red-data-modal',
  templateUrl: './red-data-modal.component.html',
  styleUrls: ['./red-data-modal.component.scss']
})
export class RedDatasModalComponent implements OnInit {
  @ViewChild('reddataForm', { static: true }) reddataForm: NgForm;

  heading: string;
  reddata: RedData = {};

  reddataData: Subject<RedData> = new Subject();

  constructor(public modalRef: MDBModalRef) { }

  ngOnInit() { 
  }

  onSave() {
    if (this.reddataForm.valid) {
      this.reddataData.next(this.reddata);
    this.modalRef.hide();
    } else {
      const controls = this.reddataForm.controls;
      Object.keys(controls).forEach( controlName => controls[controlName].markAsTouched());
    }
  }

}
