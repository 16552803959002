import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { RegisterComponent } from './auth/components/register/register.component';
import { HomeComponent } from './core/home/home.component';
import { LoginComponent } from './auth/components/login/login.component';
import { AuthGuard } from './auth/guards/auth.guard';
import { MainComponent } from './core/main/main.component';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { AdminComponent } from './admin/containers/admin/admin.component';
import { AdminGuard } from './admin/guard/admin.guard';

const routes: Routes = [
  { path: '', component: HomeComponent, children: [
    { path: '', component: MainComponent},
    { path: 'projects', loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule), canActivate: [AuthGuard]},
    { path: 'hashtags', loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule), canActivate: [AdminGuard]},
    { path: 'keywords', loadChildren: () => import('./keywords/keywords.module').then(m => m.KeywordsModule), canActivate: [AdminGuard]},
    { path: 'bluedata', loadChildren: () => import('./records/records.module').then(m => m.RecordsModule), canActivate: [AuthGuard]},
    { path: 'orangedata', loadChildren: () => import('./orange-data/orange-data.module').then(m => m.OrangeDataModule), canActivate: [AuthGuard]},
    { path: 'bluedataus', loadChildren: () => import('./bd-il/bd-il.module').then(m => m.BdilModule), canActivate: [AuthGuard]},
    { path: 'bluedatastandard', loadChildren: () => import('./blue-data/blue-data.module').then(m => m.BlueDataModule), canActivate: [AuthGuard]},
    { path: 'leaderboard', loadChildren: () => import('./leaderboard/leaderboard.module').then(m => m.LeaderboardModule), canActivate: [AuthGuard]},
    { path: 'reddatapremium', loadChildren: () => import('./companies-house/companies-house.module').then(m => m.CompaniesModule), canActivate: [AuthGuard]},
    { path: 'userdata', loadChildren: () => import('./user-data/user-data.module').then(m => m.UserDataModule), canActivate: [AuthGuard]},
    { path: 'reddatastandard', loadChildren: () => import('./red-data/red-data.module').then(m => m.RedDatasModule), canActivate: [AuthGuard]},
    { path: 'newdata', loadChildren: () => import('./new-data-found/new-data.module').then(m => m.NewDataModule), canActivate: [AdminGuard]},
    { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule), canActivate: [AuthGuard] },
    { path: 'charts', loadChildren: () => import('./charts/charts.module').then(m => m.ChartsDataModule), canActivate: [AuthGuard] },
    { path: 'admin-panel', component: AdminComponent, canActivate: [AdminGuard]}
  ]},
  { path: 'newaccount', component: RegisterComponent, canActivate: [AdminGuard] },
  { path: 'login', component: LoginComponent },
  { path: '**', component: PageNotFoundComponent }
]; 

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
