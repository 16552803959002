<div class="white px-4 section-header">
  <h3>Admin panel</h3>
  <p class="grey-text pt-3">Change settings for every user in your application</p>
  <a href="/register"><button mdbBtn class="pinkb white-text">Add New User</button></a>
  
</div> 

<div *ngIf="usersListLoading$ | async" class="d-flex justify-content-center align-items-center" style="margin-top: 300px">
  <div class="spinner-grow text-primary" role="status" style="width: 4rem; height: 4rem;">
    <span class="sr-only">Loading...</span>
  </div>
</div>
 
<div *ngIf="!(usersListLoading$ | async)" class="container-fluid mt-3 pb-5">
  
  <app-users-list *ngIf="!selectedUser" [users]="users$ | async" (userSelected)="onUserSelect($event)" (addAdmin)="addAdminPrivileges($event)"
    (removeAdmin)="removeAdminPrivileges($event)"></app-users-list>
  <app-user-detail
    *ngIf="selectedUser"
    [user]="selectedUser$ | async"
    [projects]="userProjects$ | async"
    [customers]="userCustomers$ | async"
    [keywords]="userKeywords$ | async"
    [records]="userRecords$ | async"
    [bluedata]="userBlueData$ | async"
    [orangedata]="userOrangeData$ | async"
    [bdil]="userBdil$ | async"
    [leads]="userLeads$ | async"
    [companies]="userCompanies$ | async"
    [reddatas]="userRedDatas$ | async"
    [newdata]="userNewData$ | async"
    [userdata] = "userUserData$ | async"
    [userProjectsLoading]="userProjectsLoading$ | async"
    [userCustomersLoading]="userCustomersLoading$ | async"
    [userKeywordsLoading]="userKeywordsLoading$ | async"
    [userRecordsLoading]="userRecordsLoading$ | async"
    [userBlueDataLoading]="userBlueDataLoading$ | async"
    [userOrangeDataLoading]="userOrangeDataLoading$ | async"
    [userBdilLoading]="userBdilLoading$ | async"
    [userLeadsLoading]="userLeadsLoading$ | async"
    [userNewDataLoading]="userNewDataLoading$ | async"
    [userUserDataLoading]="userUserDataLoading$ | async"
    (detailsClosed)="onDetailsClose()"
    (projectsLoad)="onProjectsLoad()"
    (customersLoad)="onCustomersLoad()"
    (keywordsLoad)="onKeywordsLoad()"
    (recordsLoad)="onRecordsLoad()"
    (bluedataLoad)="onBlueDataLoad()"
    (orangedataLoad)="onOrangeDataLoad()"
    (bdilLoad)="onBdilLoad()"
    (leadsLoad)="onLeadsLoad()"
    (companiesLoad)="onCompaniesLoad()"
    (reddatasLoad)="onRedDatasLoad()"
    (newdataLoad)="onNewDataLoad()"
    (userdataLoad)="onUserDataLoad()"
    (projectDeleted)="onProjectDelete($event)"
    (customerDeleted)="onCustomerDelete($event)"
    (keywordDeleted)="onKeywordDelete($event)"
    (recordDeleted)="onRecordDelete($event)"
    (bluedataDeleted)="onBlueDataDelete($event)"
    (orangedataDeleted)="onOrangeDataDelete($event)"
    (bdilDeleted)="onBdilDelete($event)"
    (leadDeleted)="onLeadDelete($event)"
    (companyDeleted)="onCompanyDelete($event)"
    (reddataDeleted)="onRedDataDelete($event)"
    (newdataDeleted)="onNewDataDelete($event)"
    (userdataDeleted)="onUserDataDelete($event)"
    (addAdmin)="addAdminPrivileges($event)"
    (removeAdmin)="removeAdminPrivileges($event)"></app-user-detail>
</div>
