<div class="modal-content">
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100" id="myModalLabel">{{ heading }}</h4>
  </div>
  <div class="modal-body">
    <form #reddataForm="ngForm">
      <!-- <div class="md-form">
        <input type="text" id="id" name="id" class="form-control" [(ngModel)]="record.id" mdbInput mdbValidate [validateSuccess]="false" #id="ngModel" required>
        <label for="id">Id</label>
        <mdb-error *ngIf="id && id.invalid && (id.dirty || id.touched)">Id is required</mdb-error>
      </div> -->

      <div class="md-form">
        <input type="text" id="name" name="name" class="form-control" [(ngModel)]="reddata.name" mdbInput mdbValidate [validateSuccess]="false" #name="ngModel" required>
        <label for="name">Name</label>
        <mdb-error *ngIf="name && name.invalid && (name.dirty || name.touched)">Name is required</mdb-error>
      </div>

      <div class="md-form">
        <input type="text" id="address" name="address" class="md-textarea form-control" [(ngModel)]="reddata.address" mdbInput mdbValidate [validateSuccess]="false" #address="ngModel" required>
        <label for="address">Address</label>
        <mdb-error *ngIf="address && address.invalid && (address.dirty || address.touched)">Address is required</mdb-error>
      </div>
      <div class="md-form">
        <input type="text" id="postcode" name="postcode" class="form-control" [(ngModel)]="reddata.postcode" mdbInput mdbValidate [validateSuccess]="false" #postcode="ngModel" required>
        <label for="postcode">Postcode</label>
        <mdb-error *ngIf="postcode && postcode.invalid && (postcode.dirty || postcode.touched)">Postcode Number is required</mdb-error>
      </div>
      <div class="md-form">
        <input type="text" id="date" name="date" class="form-control" [(ngModel)]="reddata.date" mdbInput mdbValidate [validateSuccess]="false" #date="ngModel" required>
        <label for="date">Date Found</label>
        <mdb-error *ngIf="date && date.invalid && (date.dirty || date.touched)">Date Found is required</mdb-error>
      </div>
      <div class="md-form">
        <input type="text" id="datesaved" name="datesaved" class="form-control" [(ngModel)]="reddata.datesaved" mdbInput mdbValidate [validateSuccess]="false" #datesaved="ngModel" required>
        <label for="datesaved">Date Saved</label>
       
      </div>
      <div class="md-form hidden">
        <input type="text" id="status" name="status" class="form-control" [(ngModel)]="reddata.status" mdbInput mdbValidate [validateSuccess]="false" #status="ngModel" required>
        <label for="date">Status</label>
        <mdb-error *ngIf="status && status.invalid && (status.dirty || status.touched)">Status is required</mdb-error>
      </div>
      <div class="md-form">
        <input type="text" id="username" name="username" class="form-control" [(ngModel)]="reddata.username" mdbInput mdbValidate [validateSuccess]="false" #username="ngModel" required>
        <label for="username">Found By</label>
       
      </div>
      <div class="md-form">
        <input type="text" id="industry" name="industry" class="form-control" [(ngModel)]="reddata.industry" mdbInput mdbValidate [validateSuccess]="false" #industry="ngModel" required>
        <label for="industry">Industry</label>
        <mdb-error *ngIf="industry && industry.invalid && (industry.dirty || industry.touched)">Industry is required</mdb-error>
      </div>
      <div class="md-form">
        <input type="text" id="phone" name="phone" class="form-control" [(ngModel)]="reddata.phone" mdbInput mdbValidate [validateSuccess]="false" #phone="ngModel" required>
        <label for="phone">Phone Number</label>
        <mdb-error *ngIf="phone && phone.invalid && (phone.dirty || phone.touched)">Phone Number is required</mdb-error>
      </div>
      <div class="md-form">
        <input type="text" id="lead" name="lead" class="form-control" [(ngModel)]="reddata.lead" mdbInput mdbValidate [validateSuccess]="false" #lead="ngModel" required>
        <label for="lead">Lead Proof</label>
        <mdb-error *ngIf="lead && lead.invalid && (lead.dirty || lead.touched)">Lead Proof is required</mdb-error>
      </div>
      <div class="md-form">
        <input type="text" id="notes" name="notes" class="form-control" [(ngModel)]="reddata.notes" mdbInput mdbValidate [validateSuccess]="false" #notes="ngModel">
        <label for="notes">Notes</label>
      
      </div>


    </form>
  </div>
  <div class="modal-footer">
    <button type="button" mdbBtn color="white" class="black-text waves-light" aria-label="Close" (click)="modalRef.hide()"
      mdbWavesEffect>Close</button>
    <button type="button" mdbBtn color="red" class="relative waves-light" mdbWavesEffect (click)="onSave()">Save</button>
  </div>
</div>
