import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { from, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private db: AngularFireDatabase) { }

  selectedUser = new Subject();
  selectedUser$ = this.selectedUser.asObservable();

  getUsersList() {
    const usersRef = this.db.list('users');
    return usersRef.snapshotChanges();
  }

  getUserProjects(uid: string) {
    const projectsRef = this.db.list('projects/' + uid);
    return projectsRef.snapshotChanges();
  }

  getUserCustomers(uid: string) {
    const customersRef = this.db.list('customers/' + uid);
    return customersRef.snapshotChanges();
  }

  getUserKeywords(uid: string) {
    const keywordsRef = this.db.list('keywords/' + uid);
    return keywordsRef.snapshotChanges();
  }

  getUserRecords(uid: string) {
    const recordsRef = this.db.list('records/' + uid);
    return recordsRef.snapshotChanges();
  }

  getUserBlueData(uid: string) {
    const bluedataRef = this.db.list('records/' + uid);
    return bluedataRef.snapshotChanges();
  }

  getUserOrangeData(uid: string) {
    const orangedataRef = this.db.list('records/' + uid);
    return orangedataRef.snapshotChanges();
  }

  getUserBdil(uid: string) {
    const bdilRef = this.db.list('records/' + uid);
    return bdilRef.snapshotChanges();
  }

  getUserLeads(uid: string) {
    const leadsRef = this.db.list('leaderboard/' + uid);
    return leadsRef.snapshotChanges();
  }

  getUserCompanies(uid: string) {
    const companiesRef = this.db.list('companies/' + uid);
    return companiesRef.snapshotChanges();
  }

  getUserRedDatas(uid: string) {
    const reddatasRef = this.db.list('reddatas/' + uid);
    return reddatasRef.snapshotChanges();
  }

  getUserNewData() {
    const newdataRef = this.db.list('newdata/EQhRw2DvorTqvPaCX95mTdm50rf2');
    return newdataRef.snapshotChanges();
  }

  getUserUserData(uid: string) {
    const userdataRef = this.db.list('newdata/' + uid);
    return userdataRef.snapshotChanges();
  }

  checkAdminRole(uid: string) {
    return this.db.object('admins/' + uid).valueChanges();
  }

  deleteUserProject(uid: string, projectId: string) {
    return from(this.db.object(`projects/${uid}/` + projectId).remove());
  }

  deleteUserCustomer(uid: string, customerId: string) {
    return from(this.db.object(`customers/${uid}/` + customerId).remove());
  }

  deleteUserKeyword(uid: string, keywordId: string) {
    return from(this.db.object(`keywords/${uid}/` + keywordId).remove());
  }

  deleteUserRecord(uid: string, recordId: string) {
    return from(this.db.object(`records/${uid}/` + recordId).remove());
  }

  deleteUserBlueData(uid: string, bluedataId: string) {
    return from(this.db.object(`records/${uid}/` + bluedataId).remove());
  }

  deleteUserOrangeData(uid: string, orangedataId: string) {
    return from(this.db.object(`records/${uid}/` + orangedataId).remove());
  }

  deleteUserBdil(uid: string, bdilId: string) {
    return from(this.db.object(`records/${uid}/` + bdilId).remove());
  }

  deleteUserCompany(uid: string, companyId: string) {
    return from(this.db.object(`companies/${uid}/` + companyId).remove()); 
  }

  deleteUserRedData(uid: string, reddataId: string) {
    return from(this.db.object(`reddatas/${uid}/` + reddataId).remove()); 
  }

  saveUserRecord(uid: string, recordId: string) {
    return from(this.db.object(`records/${uid}/` + recordId).remove());
  }

  saveUserBlueData(uid: string, bluedataId: string) {
    return from(this.db.object(`records/${uid}/` + bluedataId).remove());
  }

  saveUserOrangeData(uid: string, orangedataId: string) {
    return from(this.db.object(`records/${uid}/` + orangedataId).remove());
  }

  saveUserBdil(uid: string, bdilId: string) {
    return from(this.db.object(`records/${uid}/` + bdilId).remove());
  }

  saveUserLead(uid: string, leadId: string) {
    return from(this.db.object(`leaderboard/${uid}/` + leadId).remove());
  }


  saveUserCompany(uid: string, companyId: string) {
    return from(this.db.object(`companies/${uid}/` + companyId).remove());
  }

  saveUserRedData(uid: string, reddataId: string) {
    return from(this.db.object(`reddatas/${uid}/` + reddataId).remove());
  }

  deleteUserNewData(uid: string, newdataId: string) {
    return from(this.db.object(`newdata/${uid}/` + newdataId).remove());
  }

  saveUserNewData(uid: string, newdataId: string) {
    return from(this.db.object(`newdata/${uid}/` + newdataId).remove());
  }

  deleteUserUserData(uid: string, userdataId: string) {
    return from(this.db.object(`newdata/${uid}/` + userdataId).remove());
  }

  saveUserUserData(uid: string, userdataId: string) {
    return from(this.db.object(`newdata/${uid}/` + userdataId).remove());
  }

  addAdminPrivileges(uid: string) {
    const adminsRef = this.db.object('admins/' + uid);
    this.db.object('users/' + uid).update({ isAdmin: true });
    return from(adminsRef.set(true));
  }

  removeAdminPrivileges(uid: string) {
    this.db.object('users/' + uid).update({ isAdmin: false });
    return from(this.db.object('admins/' + uid).remove());
  }
}
