import { Action } from '@ngrx/store';
import { Project } from '../../projects/models/project.model';
import { Customer } from '../../customers/models/customer.model';
import { Keyword } from '../../keywords/models/keywords.model';
import { Record } from '../../records/models/records.model';
import { BlueData } from '../../blue-data/models/blue-data.model';
import { OrangeData } from '../../orange-data/models/orange-data.model';
import { Bdil } from '../../bd-il/models/bd-il.model';
import { Leaderboard } from '../../leaderboard/models/leaderboard.model';
import { Company } from '../../companies-house/models/companies-house.model';
import { RedData } from '../../red-data/models/red-data.model';
import { NewData } from '../../new-data-found/models/new-data.model';
import { UserData } from '../../user-data/models/user-data.model';

export enum AdminActionTypes {
  GET_USERS_LIST = '[Admin] Get Users List',
  USERS_LIST_FETCHED = '[Admin] Users list fetched',

  GET_USER_PROJECTS = '[Admin] Get user projects',
  USERS_PROJECTS_LOADED = '[Admin] User projects loaded',
  DELETE_USER_PROJECT = '[Admin] Delete user project',

  GET_USER_CUSTOMERS = '[Admin] Get user customers',
  USERS_CUSTOMERS_LOADED = '[Admin] User customers loaded',
  DELETE_USER_CUSTOMER = '[Admin] Delete user customer',

  GET_USER_KEYWORDS = '[Admin] Get user keywords',
  USERS_KEYWORDS_LOADED = '[Admin] User keywords loaded',
  DELETE_USER_KEYWORD = '[Admin] Delete user keyword',

  GET_USER_RECORDS = '[Admin] Get user records',
  USERS_RECORDS_LOADED = '[Admin] User records loaded',
  DELETE_USER_RECORD = '[Admin] Delete user record',
  SAVE_USER_RECORD = '[Admin] Save user record',

  GET_USER_BLUEDATA = '[Admin] Get user bluedata',
  USERS_BLUEDATA_LOADED = '[Admin] User bluedata loaded',
  DELETE_USER_BLUEDATA = '[Admin] Delete user bluedata',
  SAVE_USER_BLUEDATA = '[Admin] Save user bluedata',

  GET_USER_ORANGEDATA = '[Admin] Get user orangedata',
  USERS_ORANGEDATA_LOADED = '[Admin] User orangedata loaded',
  DELETE_USER_ORANGEDATA = '[Admin] Delete user orangedata',
  SAVE_USER_ORANGEDATA = '[Admin] Save user orangedata',

  GET_USER_BDIL = '[Admin] Get user bdil',
  USERS_BDIL_LOADED = '[Admin] User bdil loaded',
  DELETE_USER_BDIL = '[Admin] Delete user bdil',
  SAVE_USER_BDIL = '[Admin] Save user bdil',

  GET_USER_LEADS = '[Admin] Get user leads',
  USERS_LEADS_LOADED = '[Admin] User leads loaded',
  DELETE_USER_LEAD = '[Admin] Delete user lead',
  SAVE_USER_LEAD = '[Admin] Save user lead',

  GET_USER_COMPANIES = '[Admin] Get user companies',
  USERS_COMPANIES_LOADED = '[Admin] User companies loaded',
  DELETE_USER_COMPANY = '[Admin] Delete user company',
  SAVE_USER_COMPANY = '[Admin] Save user company',

  GET_USER_REDDATAS = '[Admin] Get user reddatas',
  USERS_REDDATAS_LOADED = '[Admin] User reddatas loaded',
  DELETE_USER_REDDATA = '[Admin] Delete user reddata',
  SAVE_USER_REDDATA = '[Admin] Save user reddata',

  GET_USER_NEWDATA = '[Admin] Get user newdata',
  USERS_NEWDATA_LOADED = '[Admin] User newdata loaded',
  DELETE_USER_NEWDATA = '[Admin] Delete user newdata',
  SAVE_USER_NEWDATA = '[Admin] Save user newdata',

  GET_USER_USERDATA = '[Admin] Get user userdata',
  USERS_USERDATA_LOADED = '[Admin] User userdata loaded',
  DELETE_USER_USERDATA = '[Admin] Delete user userdata',
  SAVE_USER_USERDATA = '[Admin] Save user userdata',

  ADD_ADMIN_PRIVILEGES = '[Admin] Add admin privileges',
  REMOVE_ADMIN_PRIVILEGES = '[Admin] Remove admin privileges',

  ADMIN_ERROR = '[Admin] Error'
}

export class GetUsersList implements Action {
  readonly type = AdminActionTypes.GET_USERS_LIST;
}

export class UsersListFetched implements Action {
  readonly type = AdminActionTypes.USERS_LIST_FETCHED;

  constructor(public payload: { usersList: any[] }) {}
}

export class GetUserProjects implements Action {
  readonly type = AdminActionTypes.GET_USER_PROJECTS;

  constructor(public payload: { uid: string }) {}
}

export class DeleteUserProject implements Action {
  readonly type = AdminActionTypes.DELETE_USER_PROJECT;

  constructor(public payload: { userId: string, projectId: string}) {}
}

export class UserProjectsLoaded implements Action {
  readonly type = AdminActionTypes.USERS_PROJECTS_LOADED;

  constructor(public payload: { uid: string, userProjects: Project[] }) {}
}

export class GetUserCustomers implements Action {
  readonly type = AdminActionTypes.GET_USER_CUSTOMERS;

  constructor(public payload: { uid: string }) {}
}

export class DeleteUserCustomer implements Action {
  readonly type = AdminActionTypes.DELETE_USER_CUSTOMER;

  constructor(public payload: { userId: string, customerId: string}) {}
}

export class UserCustomersLoaded implements Action {
  readonly type = AdminActionTypes.USERS_CUSTOMERS_LOADED;

  constructor(public payload: { uid: string, userCustomers: Customer[] }) {}
}

export class GetUserKeywords implements Action {
  readonly type = AdminActionTypes.GET_USER_KEYWORDS;

  constructor(public payload: { uid: string }) {}
}

export class DeleteUserKeyword implements Action {
  readonly type = AdminActionTypes.DELETE_USER_KEYWORD;

  constructor(public payload: { userId: string, keywordId: string}) {}
}

export class UserKeywordsLoaded implements Action {
  readonly type = AdminActionTypes.USERS_KEYWORDS_LOADED;

  constructor(public payload: { uid: string, userKeywords: Keyword[] }) {}
}

export class GetUserRecords implements Action {
  readonly type = AdminActionTypes.GET_USER_RECORDS;

  constructor(public payload: { uid: string }) {}
}

export class GetUserBlueData implements Action {
  readonly type = AdminActionTypes.GET_USER_BLUEDATA;

  constructor(public payload: { uid: string }) {}
}

export class GetUserOrangeData implements Action {
  readonly type = AdminActionTypes.GET_USER_ORANGEDATA;

  constructor(public payload: { uid: string }) {}
}

export class GetUserBdil implements Action {
  readonly type = AdminActionTypes.GET_USER_BDIL;

  constructor(public payload: { uid: string }) {}
}

export class GetUserLeads implements Action {
  readonly type = AdminActionTypes.GET_USER_LEADS;

  constructor(public payload: { uid: string }) {}
}

export class DeleteUserRecord implements Action {
  readonly type = AdminActionTypes.DELETE_USER_RECORD;

  constructor(public payload: { userId: string, recordId: string}) {}
}

export class DeleteUserBlueData implements Action {
  readonly type = AdminActionTypes.DELETE_USER_BLUEDATA;

  constructor(public payload: { userId: string, bluedataId: string}) {}
}

export class DeleteUserOrangeData implements Action {
  readonly type = AdminActionTypes.DELETE_USER_ORANGEDATA;

  constructor(public payload: { userId: string, orangedataId: string}) {}
}

export class DeleteUserBdil implements Action {
  readonly type = AdminActionTypes.DELETE_USER_BDIL;

  constructor(public payload: { userId: string, bdilId: string}) {}
}

export class SaveUserRecord implements Action {
  readonly type = AdminActionTypes.SAVE_USER_RECORD;

  constructor(public payload: { userId: string, recordId: string}) {}
}

export class SaveUserBlueData implements Action {
  readonly type = AdminActionTypes.SAVE_USER_BLUEDATA;

  constructor(public payload: { userId: string, bluedataId: string}) {}
}

export class SaveUserOrangeData implements Action {
  readonly type = AdminActionTypes.SAVE_USER_ORANGEDATA;

  constructor(public payload: { userId: string, orangedataId: string}) {}
}

export class SaveUserBdil implements Action {
  readonly type = AdminActionTypes.SAVE_USER_BDIL;

  constructor(public payload: { userId: string, bdilId: string}) {}
}

export class UserRecordsLoaded implements Action {
  readonly type = AdminActionTypes.USERS_RECORDS_LOADED;

  constructor(public payload: { uid: string, userRecords: Record[] }) {}
}

export class UserBlueDataLoaded implements Action {
  readonly type = AdminActionTypes.USERS_BLUEDATA_LOADED;

  constructor(public payload: { uid: string, userBlueData: BlueData[] }) {}
}

export class UserOrangeDataLoaded implements Action {
  readonly type = AdminActionTypes.USERS_ORANGEDATA_LOADED;

  constructor(public payload: { uid: string, userOrangeData: OrangeData[] }) {}
}

export class UserBdilLoaded implements Action {
  readonly type = AdminActionTypes.USERS_BDIL_LOADED;

  constructor(public payload: { uid: string, userBdil: Bdil[] }) {}
}

export class UserLeadsLoaded implements Action {
  readonly type = AdminActionTypes.USERS_LEADS_LOADED;

  constructor(public payload: { uid: string, userLeads: Leaderboard[] }) {}
}

export class GetUserRedDatas implements Action {
  readonly type = AdminActionTypes.GET_USER_REDDATAS;

  constructor(public payload: { uid: string }) {}
}

export class DeleteUserRedData implements Action {
  readonly type = AdminActionTypes.DELETE_USER_REDDATA;

  constructor(public payload: { userId: string, reddataId: string}) {}
}

export class SaveUserRedData implements Action {
  readonly type = AdminActionTypes.SAVE_USER_REDDATA;

  constructor(public payload: { userId: string, reddataId: string}) {}
}

export class UserRedDatasLoaded implements Action {
  readonly type = AdminActionTypes.USERS_REDDATAS_LOADED;

  constructor(public payload: { uid: string, userRedDatas: RedData[] }) {}
}

export class GetUserNewData implements Action {
  readonly type = AdminActionTypes.GET_USER_NEWDATA;

  constructor(public payload: { uid: string }) {}
}

export class DeleteUserNewData implements Action {
  readonly type = AdminActionTypes.DELETE_USER_NEWDATA;

  constructor(public payload: { userId: string, newdataId: string}) {}
}

export class SaveUserNewData implements Action {
  readonly type = AdminActionTypes.SAVE_USER_NEWDATA;

  constructor(public payload: { userId: string, newdataId: string}) {}
}

export class UserNewDataLoaded implements Action {
  readonly type = AdminActionTypes.USERS_NEWDATA_LOADED;

  constructor(public payload: { uid: string, userNewData: NewData[] }) {}
}

export class GetUserCompanies implements Action {
  readonly type = AdminActionTypes.GET_USER_COMPANIES;

  constructor(public payload: { uid: string }) {}
}

export class DeleteUserCompany implements Action {
  readonly type = AdminActionTypes.DELETE_USER_COMPANY;

  constructor(public payload: { userId: string, companyId: string}) {}
}

export class SaveUserCompany implements Action {
  readonly type = AdminActionTypes.SAVE_USER_COMPANY;

  constructor(public payload: { userId: string, companyId: string}) {}
}

export class UserCompaniesLoaded implements Action {
  readonly type = AdminActionTypes.USERS_COMPANIES_LOADED;

  constructor(public payload: { uid: string, userCompanies: Company[] }) {}
}

export class GetUserUserData implements Action {
  readonly type = AdminActionTypes.GET_USER_USERDATA;

  constructor(public payload: { uid: string }) {}
}

export class DeleteUserUserData implements Action {
  readonly type = AdminActionTypes.DELETE_USER_USERDATA;

  constructor(public payload: { userId: string, userdataId: string}) {}
}

export class SaveUserUserData implements Action {
  readonly type = AdminActionTypes.SAVE_USER_USERDATA;

  constructor(public payload: { userId: string, userdataId: string}) {}
}

export class UserUserDataLoaded implements Action {
  readonly type = AdminActionTypes.USERS_USERDATA_LOADED;

  constructor(public payload: { uid: string, userUserData: UserData[] }) {}
}

export class AddAdminPrivileges implements Action {
  readonly type = AdminActionTypes.ADD_ADMIN_PRIVILEGES;

  constructor(public payload: { userId: string }) {}
}

export class RemoveAdminPrivileges implements Action {
  readonly type = AdminActionTypes.REMOVE_ADMIN_PRIVILEGES;

  constructor(public payload: { userId: string }) {}
}

export class AdminError implements Action {
  readonly type = AdminActionTypes.ADMIN_ERROR;

  constructor(public payload: { error: any }) {}
}

export type AdminActions =
  | GetUsersList
  | UsersListFetched
  | GetUserProjects
  | UserProjectsLoaded
  | DeleteUserProject
  | GetUserCustomers
  | GetUserKeywords
  | UserCustomersLoaded
  | UserKeywordsLoaded
  | DeleteUserCustomer
  | DeleteUserKeyword
  | GetUserRecords
  | UserRecordsLoaded
  | GetUserBlueData
  | UserBlueDataLoaded
  | GetUserOrangeData
  | UserOrangeDataLoaded
  | GetUserBdil
  | UserBdilLoaded
  | GetUserLeads
  | UserLeadsLoaded
  | DeleteUserRecord
  | SaveUserRecord
  | DeleteUserBlueData
  | SaveUserBlueData
  | DeleteUserOrangeData
  | SaveUserOrangeData
  | DeleteUserBdil
  | SaveUserBdil
  | GetUserCompanies
  | GetUserRedDatas
  | UserCompaniesLoaded
  | UserRedDatasLoaded
  | DeleteUserCompany
  | DeleteUserRedData
  | SaveUserCompany
  | SaveUserRedData
  | GetUserNewData
  | UserNewDataLoaded
  | DeleteUserNewData
  | SaveUserNewData
  | SaveUserUserData
  | GetUserUserData
  | UserUserDataLoaded
  | DeleteUserUserData
  | AddAdminPrivileges
  | RemoveAdminPrivileges
  | AdminError;
