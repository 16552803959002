import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NewData } from '../../../new-data-found/models/new-data.model';

@Component({
  selector: 'app-new-data-leaderboard',
  templateUrl: './new-data-list.component.html',
  styleUrls: ['./new-data-list.component.scss']
})
export class NewDataLeaderboardComponent implements OnInit {
  @Input() newdata: NewData[];
  @Output() newdataDeleted = new EventEmitter<NewData>();
  @Output() newdataEdited = new EventEmitter<NewData>();
  @Output() newdataSaved = new EventEmitter<NewData>();

  constructor() { }

  ngOnInit() { 
  }

  onEdit(newdata: NewData) {
    this.newdataEdited.emit(newdata);
  }

  onDelete(newdata: NewData) {
    this.newdataDeleted.emit(newdata);
  }

  onSave(newdata: NewData) {
    this.newdataSaved.emit(newdata);
  }

  trackByFn(index: any) {
    return index;  
  }
}
