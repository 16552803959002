// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
  apiKey: "AIzaSyBZ1WFYuXpGnEa4pBqK1W3hwWBguqxeJss",
  authDomain: "open-utilities.firebaseapp.com",
  databaseURL: "https://open-utilities.firebaseio.com",
  projectId: "open-utilities",
  storageBucket: "open-utilities.appspot.com",
  messagingSenderId: "396414747453",
  appId: "1:396414747453:web:d2883097e238d915ff7b54",
  measurementId: "G-KY2E51ZTF1"
  }
};
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
