<mdb-card class="mb-4">
  <mdb-card-body class="py-4">
    <div class="mb-5 back-button" (click)="closeDetails()">
      <mdb-icon fas icon="arrow-left" class="mr-2"></mdb-icon>
      <span>Back to user list</span>
    </div>
    <div class="d-flex flex-row justify-content-between flex-wrap">
      <div class="d-flex flex-row">
        <div class="mr-4">
          <img *ngIf="user.photoUrl" src="{{ user.photoUrl }}" height="90" width="90" class="rounded-circle z-depth-0"
            alt="avatar image">
        </div>

        <div class="pt-2">
          <h4>{{ user.displayName }}</h4> 
          
          <div>
            <span class="mr-2">
              <mdb-badge *ngIf="!user.isOnline" color="red">Offline</mdb-badge>
              <mdb-badge *ngIf="user.isOnline" color="green">Online</mdb-badge>
            </span>
            <span>
              <mdb-badge *ngIf="user.isAdmin" color="purple">Admin</mdb-badge>
            </span>
          </div>
        </div>
      </div>

      <div class="mt-3">
        <button *ngIf="!user.isAdmin" mdbBtn outline="true" color="red" (click)="onAddAdmin()">Add admin privileges</button>
        <button *ngIf="user.isAdmin" mdbBtn outline="true" color="red" (click)="onRemoveAdmin()">Remove admin
          privileges</button>
      </div>
    </div>

    <hr class="my-5"> 

    <div class="d-flex flex-row align-items-center">
      <h4 class="mr-3">Hashtags</h4>
      <button mdbBtn class="pinkb white-text" size="sm" (click)="loadCustomers()">Load user Hashtags</button>
    </div>
    

    <div *ngIf="userCustomersLoading" class="d-flex justify-content-center align-items-center" style="margin-top: 50px">
      <div class="spinner-grow text-primary" role="status" style="width: 4rem; height: 4rem;">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <app-customers-list *ngIf="!userCustomersLoading && customers" [customers]="customers" (customerDeleted)="onCustomerDelete($event)"></app-customers-list>

    <hr class="my-5">

    <div class="d-flex flex-row align-items-center">
      <h4 class="mr-3">Keywords</h4>
      <button mdbBtn class="pinkb white-text" size="sm" (click)="loadKeywords()">Load user Keywords</button>
    </div>

    <div *ngIf="userKeywordsLoading" class="d-flex justify-content-center align-items-center" style="margin-top: 50px">
      <div class="spinner-grow text-primary" role="status" style="width: 4rem; height: 4rem;">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <app-keywords-list *ngIf="!userKeywordsLoading && keywords" [keywords]="keywords" (keywordDeleted)="onKeywordDelete($event)"></app-keywords-list>

    
    
    <hr class="my-5">

    <div class="d-flex flex-row align-items-center mb-4">
      <h4 class="mr-3">Data Found</h4>
      <button mdbBtn class="pinkb white-text" size="sm" (click)="loadNewData()">Load User Data</button>
    </div>

    <div *ngIf="userCustomersLoading" class="d-flex justify-content-center align-items-center" style="margin-top: 50px">
      <div class="spinner-grow text-primary" role="status" style="width: 4rem; height: 4rem;">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <app-new-data-leaderboard *ngIf="!userNewDataLoading && newdata" [newdata]="newdata" (newdataDeleted)="onNewDataDelete($event)"></app-new-data-leaderboard>
  
  
  
  
  </mdb-card-body> 
</mdb-card>
