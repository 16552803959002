import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Keyword } from '../models/keywords.model';
import { of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class KeywordsService {

  constructor(private db: AngularFireDatabase, private afAuth: AngularFireAuth) { }

  get userId() {
    if (this.afAuth.auth.currentUser) {
      return this.afAuth.auth.currentUser.uid;
    }
  }

  add(keyword: Keyword, userId: string) {
    const keywords = this.db.list(`keywords/${userId}`);
    return keywords.push(keyword);
  }

  addKeywords(keywords: Keyword[]) {
    const userId = this.userId;

    if (userId) {
      keywords.forEach( (keyword: Keyword) => {
        this.db.list(`keywords/${userId}`).push(keyword);
      });
    }
  }

  get(userId: string) {
    return this.db.list(`keywords/${userId}`).snapshotChanges();
  }

  update(keyword: Keyword, userId: string) {
    return of(this.db.object(`keywords/${userId}/` + keyword.key)
      .update({
        id: keyword.id,
        name: keyword.name,
        description: keyword.description,
        exclude: keyword.exclude
      }));
  }

  

  delete(keyword: Keyword, userId: string) {
    return this.db.object(`keywords/${userId}/` + keyword.key).remove();
  }
}
