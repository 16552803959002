import { Component, OnInit, ViewChild } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Leaderboard } from '../../../leaderboard/models/leaderboard.model';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-leaderboard-modal',
  templateUrl: './leaderboard-modal.component.html',
  styleUrls: ['./leaderboard-modal.component.scss']
})
export class LeaderboardModalComponent implements OnInit {
  @ViewChild('leadForm', { static: true }) leadForm: NgForm;

  heading: string;
  record: Leaderboard = {};

  leadData: Subject<Leaderboard> = new Subject();

  constructor(public modalRef: MDBModalRef) { } 

  ngOnInit() { 
  }

  onSave() {
    if (this.leadForm.valid) {
      this.leadData.next(this.record);
    this.modalRef.hide();
    } else {
      const controls = this.leadForm.controls;
      Object.keys(controls).forEach( controlName => controls[controlName].markAsTouched());
    }
  }

}
