<mdb-card>
  <mdb-card-body class="text-center py-4">
    <div>
      <span *ngIf="!user.isOnline" class="mr-2 float-left">
        <mdb-badge color="red">Offline</mdb-badge>
      </span>
      <span *ngIf="user.isOnline" class="mr-2 float-left">
        <mdb-badge color="green">Online</mdb-badge>
      </span>
      <span class="float-left">
        <mdb-badge *ngIf="user.isAdmin" color="purple">Admin</mdb-badge>
      </span>
      <mdb-icon fas icon="ellipsis-v" class="float-right grey-text"></mdb-icon>
    </div>
    <div class="mt-5">
      <img src="{{ user.photoUrl }}" *ngIf="user.photoUrl" height="90" width="90" class="rounded-circle z-depth-0" alt="avatar image">
    </div>
    
    <div class="mt-3"> 
      <b>{{ user.displayName }}</b>
      {{ user.uid }}
    </div>
    <div class="d-flex flex-column">
      <button class="mt-3" mdbBtn color="warning" size="sm" (click)="removeAdminPrivileges()" *ngIf="user.isAdmin">Remove
        admin privileges</button>
      <button class="mt-3" mdbBtn color="info" size="sm" (click)="addAdminPrivileges()" *ngIf="!user.isAdmin">Add
        admin privileges</button>
      <button class="mt-2" mdbBtn color="secondary" size="sm" (click)="selectUser()">Manage content</button>
      
    </div>
  </mdb-card-body>
</mdb-card>
