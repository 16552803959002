import { Component, OnInit, ViewChild } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Company } from '../../../companies-house/models/companies-house.model';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-companies-house-modal',
  templateUrl: './companies-house-modal.component.html',
  styleUrls: ['./companies-house-modal.component.scss']
})
export class CompaniesModalComponent implements OnInit {
  @ViewChild('companyForm', { static: true }) companyForm: NgForm;

  heading: string;
  company: Company = {};

  companyData: Subject<Company> = new Subject();

  constructor(public modalRef: MDBModalRef) { }

  ngOnInit() { 
  }

  onSave() {
    if (this.companyForm.valid) {
      this.companyData.next(this.company);
    this.modalRef.hide();
    } else {
      const controls = this.companyForm.controls;
      Object.keys(controls).forEach( controlName => controls[controlName].markAsTouched());
    }
  }

}
