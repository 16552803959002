import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { User } from '../../../auth/models/user.model';
import { Project } from '../../../projects/models/project.model';
import { Customer } from '../../../customers/models/customer.model';
import { Keyword } from '../../../keywords/models/keywords.model';
import { Record } from '../../../records/models/records.model';
import { Leaderboard } from '../../../leaderboard/models/leaderboard.model';
import { Company } from '../../../companies-house/models/companies-house.model';
import { NewData } from '../../../new-data-found/models/new-data.model';
import { RedData } from '../../../red-data/models/red-data.model';
import { BlueData } from '../../../blue-data/models/blue-data.model';
import { OrangeData } from '../../../orange-data/models/orange-data.model';
import { Bdil } from '../../../bd-il/models/bd-il.model';


@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDetailComponent implements OnInit {
  @Input() user: User;
  @Input() projects: Project[];
  @Input() customers: Customer[];
  @Input() keywords: Keyword[];
  @Input() records: Record[];
  @Input() bluedata: BlueData[];
  @Input() orangedata: OrangeData[];
  @Input() bdil: Bdil[];
  @Input() leads: Leaderboard[];
  @Input() companies: Company[];
  @Input() reddatas: RedData[];
  @Input() newdata: NewData[];
  @Input() userProjectsLoading: boolean;
  @Input() userCustomersLoading: boolean;
  @Input() userKeywordsLoading: boolean;
  @Input() userRecordsLoading: boolean;
  @Input() userBlueDataLoading: boolean;
  @Input() userOrangeDataLoading: boolean;
  @Input() userleadsLoading: boolean;
  @Input() userNewDataLoading: boolean;
  @Input() userCompaniesLoading: boolean;
  @Input() userRedDatasLoading: boolean;
  @Output() detailsClosed = new EventEmitter<any>();
  @Output() projectsLoad = new EventEmitter<any>();
  @Output() customersLoad = new EventEmitter<any>();
  @Output() keywordsLoad = new EventEmitter<any>();
  @Output() recordsLoad = new EventEmitter<any>();
  @Output() bluedataLoad = new EventEmitter<any>();
  @Output() orangedataLoad = new EventEmitter<any>();
  @Output() bdilLoad = new EventEmitter<any>();
  @Output() leadsLoad = new EventEmitter<any>();
  @Output() companiesLoad = new EventEmitter<any>();
  @Output() reddatasLoad = new EventEmitter<any>();
  @Output() newdataLoad = new EventEmitter<any>();
  @Output() projectDeleted = new EventEmitter<Project>();
  @Output() customerDeleted = new EventEmitter<Customer>();
  @Output() keywordDeleted = new EventEmitter<Keyword>();
  @Output() recordDeleted = new EventEmitter<Record>();
  @Output() bluedataDeleted = new EventEmitter<BlueData>();
  @Output() orangedataDeleted = new EventEmitter<OrangeData>();
  @Output() bdilDeleted = new EventEmitter<Bdil>();
  @Output() leadDeleted = new EventEmitter<Leaderboard>();
  @Output() companyDeleted = new EventEmitter<Company>();
  @Output() reddataDeleted = new EventEmitter<RedData>();
  @Output() newdataDeleted = new EventEmitter<NewData>();
  @Output() addAdmin = new EventEmitter<any>();
  @Output() removeAdmin = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  closeDetails() {
    this.detailsClosed.emit();
  }

  loadProjects() {
    this.projectsLoad.emit();
  }

  loadCustomers() {
    this.customersLoad.emit();
  }

  loadKeywords() {
    this.keywordsLoad.emit();
  }

  loadRecords() {
    this.recordsLoad.emit();
  }

  loadBlueData() {
    this.bluedataLoad.emit();
  }

  loadOrangeData() {
    this.orangedataLoad.emit();
  }

  loadBdil() {
    this.bdilLoad.emit();
  }

  loadLeads() {
    this.leadsLoad.emit();
  }

  loadCompanies() {
    this.companiesLoad.emit();
  }

  loadRedDatas() {
    this.reddatasLoad.emit();
  }

  loadNewData() {
    this.newdataLoad.emit();
  }

  onProjectDelete(project: Project) {
    this.projectDeleted.emit(project);
  }

  onCustomerDelete(customer: Customer) {
    this.customerDeleted.emit(customer);
  }

  onKeywordDelete(keyword: Keyword) {
    this.keywordDeleted.emit(keyword);
  }

  onRecordDelete(record: Record) {
    this.recordDeleted.emit(record)
  }

  onBlueDataDelete(bluedata: BlueData) {
    this.bluedataDeleted.emit(bluedata)
  }

  onOrangeDataDelete(orangedata: OrangeData) {
    this.orangedataDeleted.emit(orangedata)
  }

  onBdilDelete(bdil: Bdil) {
    this.bdilDeleted.emit(bdil)
  }

  onLeadDelete(lead: Leaderboard) {
    this.leadDeleted.emit(lead)
  }

  onCompanyDelete(company: Company) {
    this.companyDeleted.emit(company)
  }

  onRedDataDelete(reddata: RedData) {
    this.reddataDeleted.emit(reddata)
  }

  onNewDataDelete(newdata: NewData) {
    this.newdataDeleted.emit(newdata)
  }

  onAddAdmin() {
    this.addAdmin.emit(this.user);
  }

  onRemoveAdmin() {
    this.removeAdmin.emit(this.user);
  }
}
