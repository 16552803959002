import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Keyword } from '../../../keywords/models/keywords.model';

@Component({
  selector: 'app-keywords-list',
  templateUrl: './keywords-list.component.html',
  styleUrls: ['./keywords-list.component.scss']
})
export class KeywordsListComponent implements OnInit {
  @Input() keywords: Keyword[];
  @Output() keywordDeleted = new EventEmitter<Keyword>();
  @Output() keywordEdited = new EventEmitter<Keyword>();

  constructor() { }

  ngOnInit() {
  }

  onEdit(keyword: Keyword) {
    this.keywordEdited.emit(keyword);
  }

  onDelete(keyword: Keyword) {
    this.keywordDeleted.emit(keyword);
  }

  trackByFn(index: any) {
    return index;
  }
}
