<div class="container py-5">
  <div class="row">
    <div class="col-md-6">
      <mdb-card class="animated fadeIn mb-3" borderColor="border-danger">
        <mdb-card-header></mdb-card-header>
        <mdb-card-body class="p-3">
          <mdb-card-title>
            <h5 class="mb-3">My Account</h5>
          </mdb-card-title>
          <mdb-card-text>Change your account settings.</mdb-card-text>
          <a routerLink="/profile" mdbBtn color="pink">Go to My Account</a>
        </mdb-card-body>
      </mdb-card>
    </div>

    <div class="col-md-6">
      <mdb-card class="animated fadeIn mb-3" borderColor="border-danger">
        <mdb-card-header></mdb-card-header>
        <mdb-card-body class="p-3">
          <mdb-card-title>
            <h5 class="mb-3">My Hashtags</h5>
          </mdb-card-title>
          <mdb-card-text>View Your Saved Hashtags.</mdb-card-text>
          <a routerLink="/hashtags" mdbBtn color="pink">Go to Hashtags</a>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
  <div class="row">

    <div class="col-md-6">
      <mdb-card class="animated fadeIn mb-3" borderColor="border-danger">
        <mdb-card-header></mdb-card-header>
        <mdb-card-body class="p-3">
          <mdb-card-title>
            <h5 class="mb-3">Facebook Data</h5>
          </mdb-card-title>
          <mdb-card-text>View Live Facebook Data.</mdb-card-text>
          <a routerLink="/facebookdata" mdbBtn color="pink">Go to Facebook Data</a>
        </mdb-card-body>
      </mdb-card>
    </div>

    <div class="col-md-6">
      <mdb-card class="animated fadeIn mb-3" borderColor="border-danger">
        <mdb-card-header></mdb-card-header>
        <mdb-card-body class="p-3">
          <mdb-card-title>
            <h5 class="mb-3">Red Data</h5>
          </mdb-card-title>
          <mdb-card-text>View Live Red Data.</mdb-card-text>
          <a routerLink="/reddata" mdbBtn color="pink">Go to Red Data</a>
        </mdb-card-body>
      </mdb-card>
    </div>

  </div>
</div>