// @ts-(expect-error|ignore)
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Company } from '../models/companies-house.model';
import { of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(private db: AngularFireDatabase, private afAuth: AngularFireAuth) { }

  get userId() {
    if (this.afAuth.auth.currentUser) {
      return this.afAuth.auth.currentUser.uid;
    }
  }

  add(company: Company, userId: string) {
    const companies = this.db.list(`companies/${userId}`);
    return companies.push(company); 
  }

  addCompanies(companies: Company[]) {
    const userId = this.userId;

    if (userId) {
      companies.forEach( (company: Company) => {
        this.db.list(`companies/${userId}`).push(company);
      });
    }
  }

  get(userId: string) {
    return this.db.list(`companies/${userId}`).snapshotChanges();
  }

  update(company: Company, userId: string) {
    const moverec = this.db.object(`companies/${userId}/` + company.key);
    const duperec = this.db.object(`newdata/${userId}/` + company.key);
    moverec.remove();
    duperec.update({id: company.id, name: company.name, description: company.description, phone: company.phone, page: company.page, address: company.address, sic: company.sic, incdate: company.incdate, postcode: company.postcode, date: company.date, status: company.status, industry: company.sic, username: company.username, lead: company.lead});
    const time1 = new Date().getUTCHours();
    const time2 = new Date().getUTCMinutes();
    const time3 = new Date().getUTCSeconds();
    const time4 = new Date().getUTCMilliseconds();
    const myDate = new Date().getDate();
    const myDate2 = new Date().getMonth() + 1;
    const myDate3 = new Date().getUTCFullYear();
    const fullDate = [myDate, myDate2, myDate3, time1, time2, time3, time4].join();
    
    return of(this.db.object(`newdata/EQhRw2DvorTqvPaCX95mTdm50rf2/` + fullDate)
      .update({
        id: company.id,
        name: company.name,
        description: company.description,
        phone: company.phone,
        page: company.page, 
        address: company.address, 
        sic: company.sic,
        incdate: company.incdate,
        postcode: company.postcode,
        date: company.date,
        status: company.status,
        industry: company.sic,
        username: company.username,
        lead: company.lead,
        notes: company.notes,
        datesaved: company.datesaved
      }));
      
  }

  save(company: Company, userId: string) {
    const moverec = this.db.object(`companies/${userId}/` + company.key);
    const duperec = this.db.object(`newdata/${userId}/` + company.key);
    moverec.remove();  
    duperec.update({id: company.id, name: company.name, description: company.description, phone: company.phone, page: company.page, address: company.address, sic: company.sic, incdate: company.incdate, postcode: company.postcode, date: company.date, status: company.status, industry: company.sic, username: company.username, lead: company.lead});
    const time1 = new Date().getUTCHours();
    const time2 = new Date().getUTCMinutes();
    const time3 = new Date().getUTCSeconds();
    const time4 = new Date().getUTCMilliseconds();
    const myDate = new Date().getDate();
    const myDate2 = new Date().getMonth() + 1;
    const myDate3 = new Date().getUTCFullYear();
    const fullDate = [myDate, myDate2, myDate3, time1, time2, time3, time4].join();
    return of(this.db.object(`newdata/EQhRw2DvorTqvPaCX95mTdm50rf2/` + fullDate)
      .update({
        id: company.id,
        name: company.name,
        description: company.description,
        phone: company.phone,
        page: company.page,
        address: company.address,
        sic: company.sic,
        incdate: company.incdate,
        postcode: company.postcode,
        date: company.date,
        status: company.status,
        industry: company.sic,
        username: company.username,
        lead: company.lead,
        notes: company.notes,
        datesaved: company.datesaved
      }));
      
  }

  delete(company: Company, userId: string) {
    return this.db.object(`companies/${userId}/` + company.key).remove();
  }

  deleteAll(company: Company, userId: string) {
    return this.db.object(`companies/${userId}/`).remove(), company.key;
  }
}
