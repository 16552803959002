<div class="z-depth-1">
    <table class="table table-striped table-responsive-md btn-table" style="text-align: center;" mdbTable borderless="true">
      <thead style="background-color: transparent; text-align: left;">
        <tr>
          <!-- <th>Id</th> -->
          <th>Lead Count</th>
          <th>Company Name</th>
          <th>Address</th>
          <th>Postcode</th>
          <th>Status</th>
          <th>Page</th>
          
          <th>Phone Number</th>
          <th>Secondary contact</th>
          <th>industry Type</th>
          <th>Date Found</th>
          <th>Lead Proof</th>
          <th>User</th>
         
        </tr> 
      </thead>
  
      <tbody>
        <tr *ngFor="let userdata of userdata; trackBy: trackByFn; let i = index;" style="text-align: left;">
          <td>{{ i + 1 }}</td>
          <td scope="row">{{ userdata.name }}</td>
          <td>{{ userdata.address }}</td>
          <td>{{ userdata.postcode }}</td>
          <td>{{ userdata.status }}</td>
          <td><a href="{{ userdata.page }}" target="_blank">View Page</a></td>
          <!-- <td><a href="{{ newdata.description }}" target="_blank">View Post</a></td> -->
          <td>{{ userdata.phone }}</td> 
          <td>{{ userdata.phone2 }}</td>
          <td>{{ userdata.industry }}</td>
          <td>{{ userdata.date }}</td>
          <td><a href="{{ userdata.lead }}" target="_blank">View Lead Proof</a></td>
          <td>{{ userdata.username }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  