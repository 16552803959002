import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as fromAdmin from './../store/admin.actions';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { AdminService } from '../services/admin.service';
import { Project } from '../../projects/models/project.model';
import { of } from 'rxjs';
import { Customer } from '../../customers/models/customer.model';
import { Keyword } from '../../keywords/models/keywords.model';
import { Record } from '../../records/models/records.model';
import { BlueData } from '../../blue-data/models/blue-data.model';
import { OrangeData } from '../../orange-data/models/orange-data.model';
import { Bdil } from '../../bd-il/models/bd-il.model';
import { Leaderboard } from '../../leaderboard/models/leaderboard.model';
import { Company } from '../../companies-house/models/companies-house.model';
import { NewData } from '../../new-data-found/models/new-data.model';
import { UserData } from '../../user-data/models/user-data.model';
import { RedData } from '../../red-data/models/red-data.model';


@Injectable()
export class AdminEffects {

  constructor(private actions$: Actions, private adminService: AdminService) {}

  @Effect()
  getUsersList$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.GET_USERS_LIST),
    switchMap( () => this.adminService.getUsersList()
      .pipe(
        map( (users: any) => {
          const usersList: any[] = users.map((res: any) => {
            const key = res.payload.key;
            const user: any = res.payload.val();
            return {
              key: key,
              uid: user.uid,
              displayName: user.displayName,
              email: user.email,
              providerId: user.providerId,
              photoUrl: user.photoUrl,
              isNewUser: user.isNewUser,
              isAdmin: user.isAdmin,
              isOnline: user.isOnline
            };
          });
          return (new fromAdmin.UsersListFetched({ usersList }));
        }),
        catchError( (error: any) => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect()
  getUserProjects$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.GET_USER_PROJECTS),
    map((action: fromAdmin.GetUserProjects) => action.payload),
    mergeMap( (payload: any) => this.adminService.getUserProjects(payload.uid)
      .pipe(
        map((data: any) => {
          const projectsData: Project[] = data.map((res: any) => {
            const key = res.payload.key;
            const project: Project = res.payload.val();
            return {
              key: key || null,
              title: project.title || null,
              description: project.description || null,
              photoUrl: project.photoUrl || null
            };
          });
          return (new fromAdmin.UserProjectsLoaded({ uid: payload.uid, userProjects: projectsData }));
        }),
        catchError(error => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteUserProject$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.DELETE_USER_PROJECT),
    map( (action: fromAdmin.DeleteUserProject) => action.payload),
    switchMap( (payload: any) => this.adminService.deleteUserProject(payload.userId, payload.projectId)
      .pipe(
        catchError( (error: any) => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect()
  getUserCustomers$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.GET_USER_CUSTOMERS),
    map((action: fromAdmin.GetUserCustomers) => action.payload),
    mergeMap( (payload: any) => this.adminService.getUserCustomers(payload.uid)
      .pipe(
        map((data: any) => {
          const customersData: Customer[] = data.map((res: any) => {
            const key = res.payload.key;
            const customer: Customer = res.payload.val();
            return {
              key: key,
              id: customer.id,
              name: customer.name,
              description: customer.description
            };
          });
          return (new fromAdmin.UserCustomersLoaded({ uid: payload.uid, userCustomers: customersData }));
        }),
        catchError(error => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteUserCustomer$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.DELETE_USER_CUSTOMER),
    map( (action: fromAdmin.DeleteUserCustomer) => action.payload),
    switchMap( (payload: any) => this.adminService.deleteUserCustomer(payload.userId, payload.customerId)
      .pipe(
        catchError( (error: any) => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect()
  getUserKeywords$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.GET_USER_KEYWORDS),
    map((action: fromAdmin.GetUserKeywords) => action.payload),
    mergeMap( (payload: any) => this.adminService.getUserKeywords(payload.uid)
      .pipe(
        map((data: any) => {
          const keywordsData: Keyword[] = data.map((res: any) => {
            const key = res.payload.key;
            const keyword: Keyword = res.payload.val();
            return {
              key: key,
              id: keyword.id,
              exclude: keyword.exclude,
              name: keyword.name,
              description: keyword.description
            };
          });
          return (new fromAdmin.UserKeywordsLoaded({ uid: payload.uid, userKeywords: keywordsData }));
        }),
        catchError(error => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteUserKeyword$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.DELETE_USER_KEYWORD),
    map( (action: fromAdmin.DeleteUserKeyword) => action.payload),
    switchMap( (payload: any) => this.adminService.deleteUserKeyword(payload.userId, payload.keywordId)
      .pipe(
        catchError( (error: any) => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect()
  getUserRecords$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.GET_USER_RECORDS),
    map((action: fromAdmin.GetUserRecords) => action.payload),
    mergeMap( (payload: any) => this.adminService.getUserRecords(payload.uid)
      .pipe(
        map((data: any) => {
          const recordsData: Record[] = data.map((res: any) => {
            const key = res.payload.key;
            const record: Record = res.payload.val();
            return {
              key: key,
              id: record.id,
              name: record.name,
              description: record.description
            };
          });
          return (new fromAdmin.UserRecordsLoaded({ uid: payload.uid, userRecords: recordsData }));
        }),
        catchError(error => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect()
  getUserBlueData$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.GET_USER_BLUEDATA),
    map((action: fromAdmin.GetUserBlueData) => action.payload),
    mergeMap( (payload: any) => this.adminService.getUserBlueData(payload.uid)
      .pipe(
        map((data: any) => {
          const bluedataData: BlueData[] = data.map((res: any) => {
            const key = res.payload.key;
            const bluedata: BlueData = res.payload.val();
            return {
              key: key,
              id: bluedata.id,
              name: bluedata.name,
              description: bluedata.description
            };
          });
          return (new fromAdmin.UserBlueDataLoaded({ uid: payload.uid, userBlueData: bluedataData }));
        }),
        catchError(error => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect()
  getUserOrangeData$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.GET_USER_ORANGEDATA),
    map((action: fromAdmin.GetUserOrangeData) => action.payload),
    mergeMap( (payload: any) => this.adminService.getUserOrangeData(payload.uid)
      .pipe(
        map((data: any) => {
          const orangedataData: OrangeData[] = data.map((res: any) => {
            const key = res.payload.key;
            const orangedata: OrangeData = res.payload.val();
            return {
              key: key,
              id: orangedata.id,
              name: orangedata.name,
              description: orangedata.description
            };
          });
          return (new fromAdmin.UserOrangeDataLoaded({ uid: payload.uid, userOrangeData: orangedataData }));
        }),
        catchError(error => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect()
  getUserBdil$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.GET_USER_BDIL),
    map((action: fromAdmin.GetUserBdil) => action.payload),
    mergeMap( (payload: any) => this.adminService.getUserBdil(payload.uid)
      .pipe(
        map((data: any) => {
          const bdilData: Bdil[] = data.map((res: any) => {
            const key = res.payload.key;
            const bdil: Bdil = res.payload.val();
            return {
              key: key,
              id: bdil.id,
              name: bdil.name,
              description: bdil.description
            };
          });
          return (new fromAdmin.UserBdilLoaded({ uid: payload.uid, userBdil: bdilData }));
        }),
        catchError(error => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect()
  getUserLeads$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.GET_USER_LEADS),
    map((action: fromAdmin.GetUserLeads) => action.payload),
    mergeMap( (payload: any) => this.adminService.getUserLeads(payload.uid)
      .pipe(
        map((data: any) => {
          const leadsData: Leaderboard[] = data.map((res: any) => {
            const key = res.payload.key;
            const lead: Leaderboard = res.payload.val();
            return {
              key: key,
              id: lead.id,
              name: lead.name,
              description: lead.description
            };
          });
          return (new fromAdmin.UserLeadsLoaded({ uid: payload.uid, userLeads: leadsData }));
        }),
        catchError(error => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteUserRecord$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.DELETE_USER_RECORD),
    map( (action: fromAdmin.DeleteUserRecord) => action.payload),
    switchMap( (payload: any) => this.adminService.deleteUserRecord(payload.userId, payload.recordId)
      .pipe(
        catchError( (error: any) => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  saveUserRecord$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.SAVE_USER_RECORD),
    map( (action: fromAdmin.SaveUserRecord) => action.payload),
    switchMap( (payload: any) => this.adminService.saveUserRecord(payload.userId, payload.recordId)
      .pipe(
        catchError( (error: any) => of(new fromAdmin.AdminError({ error })))
      )
    )
  );


  @Effect({ dispatch: false })
  deleteUserBlueData$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.DELETE_USER_BLUEDATA),
    map( (action: fromAdmin.DeleteUserBlueData) => action.payload),
    switchMap( (payload: any) => this.adminService.deleteUserBlueData(payload.userId, payload.bluedataId)
      .pipe(
        catchError( (error: any) => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  saveUserBlueData$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.SAVE_USER_BLUEDATA),
    map( (action: fromAdmin.SaveUserBlueData) => action.payload),
    switchMap( (payload: any) => this.adminService.saveUserBlueData(payload.userId, payload.bluedataId)
      .pipe(
        catchError( (error: any) => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteUserOrangeData$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.DELETE_USER_ORANGEDATA),
    map( (action: fromAdmin.DeleteUserOrangeData) => action.payload),
    switchMap( (payload: any) => this.adminService.deleteUserOrangeData(payload.userId, payload.orangedataId)
      .pipe(
        catchError( (error: any) => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  saveUserOrangeData$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.SAVE_USER_ORANGEDATA),
    map( (action: fromAdmin.SaveUserOrangeData) => action.payload),
    switchMap( (payload: any) => this.adminService.saveUserOrangeData(payload.userId, payload.orangedataId)
      .pipe(
        catchError( (error: any) => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteUserBdil$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.DELETE_USER_BDIL),
    map( (action: fromAdmin.DeleteUserBdil) => action.payload),
    switchMap( (payload: any) => this.adminService.deleteUserBdil(payload.userId, payload.bdilId)
      .pipe(
        catchError( (error: any) => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  saveUserBdil$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.SAVE_USER_BDIL),
    map( (action: fromAdmin.SaveUserBdil) => action.payload),
    switchMap( (payload: any) => this.adminService.saveUserBdil(payload.userId, payload.bdilId)
      .pipe(
        catchError( (error: any) => of(new fromAdmin.AdminError({ error })))
      )
    )
  );
 

  @Effect()
  getUserRedDatas$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.GET_USER_REDDATAS),
    map((action: fromAdmin.GetUserRedDatas) => action.payload),
    mergeMap( (payload: any) => this.adminService.getUserRedDatas(payload.uid)
      .pipe(
        map((data: any) => {
          const reddatasData: RedData[] = data.map((res: any) => {
            const key = res.payload.key;
            const reddata: RedData = res.payload.val();
            return {
              key: key,
              id: reddata.id,
              name: reddata.name,
              description: reddata.description
            };
          });
          return (new fromAdmin.UserRedDatasLoaded({ uid: payload.uid, userRedDatas: reddatasData }));
        }),
        catchError(error => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteUserRedData$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.DELETE_USER_REDDATA),
    map( (action: fromAdmin.DeleteUserRedData) => action.payload),
    switchMap( (payload: any) => this.adminService.deleteUserRedData(payload.userId, payload.reddataId)
      .pipe(
        catchError( (error: any) => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  saveUserRedData$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.SAVE_USER_REDDATA),
    map( (action: fromAdmin.SaveUserRedData) => action.payload),
    switchMap( (payload: any) => this.adminService.saveUserRedData(payload.userId, payload.reddataId)
      .pipe(
        catchError( (error: any) => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect()
  getUserNewData$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.GET_USER_NEWDATA),
    map((action: fromAdmin.GetUserNewData) => action.payload),
    mergeMap( (payload: any) => this.adminService.getUserNewData()
      .pipe(
        map((data: any) => {
          const newdataData: NewData[] = data.map((res: any) => {
            const key = res.payload.key;
            const newdata: NewData = res.payload.val();
            return {
              key: key,
              id: newdata.id,
              name: newdata.name,
              description: newdata.description
            };
          });
          return (new fromAdmin.UserNewDataLoaded({ uid: payload.uid, userNewData: newdataData }));
        }),
        catchError(error => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteUserNewData$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.DELETE_USER_NEWDATA),
    map( (action: fromAdmin.DeleteUserNewData) => action.payload),
    switchMap( (payload: any) => this.adminService.deleteUserNewData(payload.userId, payload.newdataId)
      .pipe(
        catchError( (error: any) => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  saveUserNewData$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.SAVE_USER_NEWDATA),
    map( (action: fromAdmin.SaveUserNewData) => action.payload),
    switchMap( (payload: any) => this.adminService.saveUserNewData(payload.userId, payload.newdataId)
      .pipe(
        catchError( (error: any) => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect()
  getUserUserData$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.GET_USER_USERDATA),
    map((action: fromAdmin.GetUserUserData) => action.payload),
    mergeMap( (payload: any) => this.adminService.getUserUserData(payload.uid)
      .pipe(
        map((data: any) => {
          const userdataData: UserData[] = data.map((res: any) => {
            const key = res.payload.key;
            const userdata: UserData = res.payload.val();
            return {
              key: key,
              id: userdata.id,
              name: userdata.name,
              description: userdata.description
            };
          });
          return (new fromAdmin.UserUserDataLoaded({ uid: payload.uid, userUserData: userdataData }));
        }),
        catchError(error => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteUserUserData$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.DELETE_USER_USERDATA),
    map( (action: fromAdmin.DeleteUserUserData) => action.payload),
    switchMap( (payload: any) => this.adminService.deleteUserUserData(payload.userId, payload.userdataId)
      .pipe(
        catchError( (error: any) => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  saveUserUserData$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.SAVE_USER_USERDATA),
    map( (action: fromAdmin.SaveUserUserData) => action.payload),
    switchMap( (payload: any) => this.adminService.saveUserUserData(payload.userId, payload.userdataId)
      .pipe(
        catchError( (error: any) => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect()
  getUserCompanies$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.GET_USER_COMPANIES),
    map((action: fromAdmin.GetUserCompanies) => action.payload),
    mergeMap( (payload: any) => this.adminService.getUserCompanies(payload.uid)
      .pipe(
        map((data: any) => {
          const companiesData: Company[] = data.map((res: any) => {
            const key = res.payload.key;
            const company: Company = res.payload.val();
            return {
              key: key,
              id: company.id,
              name: company.name,
              description: company.description
            };
          });
          return (new fromAdmin.UserCompaniesLoaded({ uid: payload.uid, userCompanies: companiesData }));
        }),
        catchError(error => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteUserCompany$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.DELETE_USER_COMPANY),
    map( (action: fromAdmin.DeleteUserCompany) => action.payload),
    switchMap( (payload: any) => this.adminService.deleteUserCompany(payload.userId, payload.companyId)
      .pipe(
        catchError( (error: any) => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  saveUserCompany$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.SAVE_USER_COMPANY),
    map( (action: fromAdmin.SaveUserCompany) => action.payload),
    switchMap( (payload: any) => this.adminService.saveUserCompany(payload.userId, payload.companyId)
      .pipe(
        catchError( (error: any) => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  addAdminPrivileges$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.ADD_ADMIN_PRIVILEGES),
    map( (action: fromAdmin.AddAdminPrivileges) => action.payload),
    switchMap( (payload: any) => this.adminService.addAdminPrivileges(payload.userId)
      .pipe(
        catchError( (error: any) => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  removeAdminPrivileges$ = this.actions$.pipe(
    ofType(fromAdmin.AdminActionTypes.REMOVE_ADMIN_PRIVILEGES),
    map( (action: fromAdmin.RemoveAdminPrivileges) => action.payload),
    switchMap( (payload: any) => this.adminService.removeAdminPrivileges(payload.userId)
      .pipe(
        catchError( (error: any) => of(new fromAdmin.AdminError({ error })))
      )
    )
  );

  
}
