import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Leaderboard } from '../../../leaderboard/models/leaderboard.model'
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-save-modal.component.html',
  styleUrls: ['./confirm-save-modal.component.scss']
})
export class ConfirmSaveModalComponent implements OnInit {

  record: Leaderboard = {};

  recordData: Subject<Leaderboard> = new Subject();

  constructor(public modalRef: MDBModalRef) { }

  ngOnInit() {
  }

  onSave() {
    this.recordData.next(this.record);
    this.modalRef.hide();
  }

}
