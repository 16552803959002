import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RedData } from '../../../red-data/models/red-data.model';



@Component({
  selector: 'app-red-data-list',
  templateUrl: './red-data-list.component.html',
  styleUrls: ['./red-data-list.component.scss']
})
export class RedDatasListComponent implements OnInit {
  @Input() reddatas: RedData[];
  @Output() reddataDeleted = new EventEmitter<RedData>();
  @Output() reddataDeletedAll = new EventEmitter<RedData>();
  @Output() reddataEdited = new EventEmitter<RedData>();
  @Output() reddataSaved = new EventEmitter<RedData>();

  

  constructor() { }

  ngOnInit() {
  }

  onEdit(reddata: RedData) { 
    this.reddataEdited.emit(reddata);
  }

  onDelete(reddata: RedData) {
    this.reddataDeleted.emit(reddata); 
  }

  onDeleteAll(reddata: RedData) {
    this.reddataDeletedAll.emit(reddata); 
  }

  onSave(reddata: RedData) {
    this.reddataSaved.emit(reddata);
  }

  trackByFn(index: any) {
    return index;
  }

  

}
