// import { Project } from 'src/app/projects/models/project.model';

export interface AdminState {
    usersList: any[];
    usersListLoading: boolean;
    userProjects: any;
    userProjectsLoading: boolean;
    userCustomers: any;
    userKeywords: any;
    userCustomersLoading: boolean;
    userKeywordsLoading: boolean;
    userRecords: any;
    userRecordsLoading: boolean;
    userBlueData: any;
    userBlueDataLoading: boolean;
    userOrangeData: any;
    userOrangeDataLoading: boolean;
    userBdil: any;
    userBdilLoading: boolean;
    userLeads: any;
    userLeadsLoading: boolean;
    userCompanies: any;
    userCompaniesLoading: boolean;
    userNewData: any; 
    userUserData: any;
    userRedDatasLoading: boolean;
    userRedDatas: any; 
    userNewDataLoading: boolean;
    userUserDataLoading: boolean;
    error: any;
}

export const adminInitialState: AdminState = {
    usersList: [],
    usersListLoading: false,
    userProjects: {},
    userProjectsLoading: false,
    userCustomers: {},
    userCustomersLoading: false,
    userKeywords: {},
    userKeywordsLoading: false,
    userRecords: {},
    userRecordsLoading: false,
    userBlueData: {},
    userBlueDataLoading: false,
    userOrangeData: {},
    userOrangeDataLoading: false,
    userBdil: {},
    userBdilLoading: false,
    userLeads: {},
    userLeadsLoading: false,
    userCompanies: {},
    userCompaniesLoading: false,
    userRedDatas: {},
    userRedDatasLoading: false,
    userNewData: {},
    userNewDataLoading: false, 
    userUserData: {},
    userUserDataLoading: false,
    error: null
};
