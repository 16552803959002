<div class="container-fluid full-page-container">
  <div class="row h-100 justify-content-center align-items-center">
    <div class="col-lg-4 col-sm-8 animated fadeIn">
      <!-- Material form login -->
      <mdb-card class="z-depth-2"> 

        <div class="text-center py-4">
          <h5> 
           
          </h5>
        </div>


        <!--Card content-->
        <mdb-card-body class="px-lg-5 pt-0">
          <div *ngIf="error$ | async as error" class="alert alert-danger" role="alert">
            {{ error }}
          </div>
          <!-- Form -->
          <form [formGroup]="registerForm" class="text-center" style="color: #757575;">

            <!-- Username -->
            <div class="md-form">
              <input type="text" id="username" class="form-control" formControlName="username" mdbInput>
              <label for="username">Username</label>
            </div>

            <!-- Email -->
            <div class="md-form">
              <input mdbValidate [validateSuccess]="false" type="email" id="email" class="form-control" formControlName="email" mdbInput>
              <label for="email">E-mail</label>
              <mdb-error *ngIf="email && email.invalid && (email.dirty || email.touched)">Email is required</mdb-error>
            </div>

            <!-- Password -->
            <div class="md-form">
              <input mdbValidate [validateSuccess]="false" type="password" id="password" class="form-control" formControlName="password" mdbInput>
              <label for="password">Password</label>
              <mdb-error *ngIf="password && password.invalid && password.errors && password.errors.required && (password.dirty || password.touched)">Password is required</mdb-error>
              <mdb-error *ngIf="password && password.invalid && password.errors && password.errors.minlength && (password.dirty || password.touched)">Password must be at least 6 characters long</mdb-error>
            </div>

            <!-- Sign in button -->
            <button mdbBtn color="primary" block="true" class="my-4 waves-effect z-depth-0"
              mdbWavesEffect type="submit" (click)="onRegister()">Add</button>

            <!-- Register -->
            

          </form>
          <!-- Form -->

        </mdb-card-body>

      </mdb-card>
      <!-- Material form login -->
    </div>
  </div>
</div>
