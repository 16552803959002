import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RedData } from '../../../red-data/models/red-data.model';


@Component({
  selector: 'app-companies-house-list2',
  templateUrl: './companies-house-list2.component.html',
  styleUrls: ['./companies-house-list2.component.scss']
})
export class CompaniesList2Component implements OnInit {
  @Input() reddatas: RedData[];
  @Output() companyDeleted = new EventEmitter<RedData>();
  @Output() companyEdited = new EventEmitter<RedData>();
  @Output() companySaved = new EventEmitter<RedData>();
  

  

  constructor() { }

  ngOnInit() {
  }

  onEdit(reddata: RedData) {
    this.companyEdited.emit(reddata);
  }

  onDelete(reddata: RedData) {
    this.companyDeleted.emit(reddata); 
  }

  onDeleteAll(reddata: RedData) {
    this.companyDeleted.emit(reddata); 
  }

  onSave(reddata: RedData) {
    this.companySaved.emit(reddata);
  }

  trackByFn(index: any) {
    return index;
  }

  

}
