import { Component, OnInit, ViewChild } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { NewData } from '../../../new-data-found/models/new-data.model';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-newdata-modal',
  templateUrl: './newdata-modal.component.html',
  styleUrls: ['./newdata-modal.component.scss']
})
export class NewdataModalComponent implements OnInit {
  @ViewChild('newdataForm', {static: true}) newdataForm: NgForm;

  heading: string; 
  newdata: NewData = {};

  newdataData: Subject<NewData> = new Subject();

  constructor(public modalRef: MDBModalRef) { }

  ngOnInit() { 
 
  }

  onSave() {
    if (this.newdataForm.valid) {
      this.newdataData.next(this.newdata);
      this.modalRef.hide();
    } else {
      const controls = this.newdataForm.controls;
      Object.keys(controls).forEach( controlName => controls[controlName].markAsTouched());
    }
  }
 
}
