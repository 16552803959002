import { Component, OnInit, ViewChild } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Bdil } from '../../../bd-il/models/bd-il.model';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-bdil-modal',
  templateUrl: './bd-il-modal.component.html',
  styleUrls: ['./bd-il-modal.component.scss']
})
export class BdilModalComponent implements OnInit {
  @ViewChild('bdilForm', { static: true }) bdilForm: NgForm;

  heading: string;
  bdil: Bdil = {};

  bdilData: Subject<Bdil> = new Subject();

  constructor(public modalRef: MDBModalRef) { } 

  ngOnInit() { 
  }

  onSave() {
    if (this.bdilForm.valid) {
      this.bdilData.next(this.bdil);
    this.modalRef.hide();
    } else {
      const controls = this.bdilForm.controls;
      Object.keys(controls).forEach( controlName => controls[controlName].markAsTouched());
    }
  }

}
