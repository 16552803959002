import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { ConfirmSaveModalComponent } from './components/confirm-save-modal/confirm-save-modal.component'
import {
  ButtonsModule,
  InputsModule,
  CardsModule,
  InputUtilitiesModule,
  IconsModule
} from 'angular-bootstrap-md';
import { CustomersModalComponent } from './components/customers-modal/customers-modal.component';
import { KeywordsModalComponent } from './components/keywords-modal/keywords-modal.component';
import { RecordsModalComponent } from './components/records-modal/records-modal.component';
import { BlueDataModalComponent } from './components/blue-data-modal/blue-data-modal.component';
import { OrangeDataModalComponent } from './components/orange-data-modal/orange-data-modal.component';
import { BdilModalComponent } from './components/bd-il-modal/bd-il-modal.component';
import { LeaderboardModalComponent } from './components/leaderboard-modal/leaderboard-modal.component';
import { CompaniesModalComponent } from './components/companies-house-modal/companies-house-modal.component';
import { RedDatasModalComponent } from './components/red-data-modal/red-data-modal.component';
import { ProjectModalComponent } from './components/project-modal/project-modal.component';
import { FormsModule } from '@angular/forms';
import { ProjectComponent } from './components/project/project.component';
import { ProjectsListComponent } from './components/projects-list/projects-list.component';
import { CustomersListComponent } from './components/customers-list/customers-list.component';
import { KeywordsListComponent } from './components/keywords-list/keywords-list.component';
import { RecordsListComponent } from './components/records-list/records-list.component';
import { BlueDataListComponent } from './components/blue-data-list/blue-data-list.component';
import { OrangeDataListComponent } from './components/orange-data-list/orange-data-list.component';
import { BdilListComponent } from './components/bd-il-list/bd-il-list.component';
import { LeaderboardListComponent } from './components/leaderboard-list/leaderboard-list.component';
import { CompaniesListComponent } from './components/companies-house-list/companies-house-list.component';
import { CompaniesList2Component } from './components/companies-house-list/companies-house-list2.component';
import { RedDatasListComponent } from './components/red-data-list/red-data-list.component';
import { NewDataListComponent } from './components/new-data-list/new-data-list.component';
import { NewDataLeaderboardComponent } from './components/new-data-leaderboard/new-data-list.component';
import { UserDataListComponent } from './components/user-data-list/user-data-list.component';
import { UserdataModalComponent } from './components/user-data-modal/userdata-modal.component'; 
import { NewdataModalComponent } from './components/newdata-modal/newdata-modal.component';
// import { from } from 'rxjs';


@NgModule({
  declarations: [
    ConfirmModalComponent,
    ConfirmSaveModalComponent,
    CustomersModalComponent,
    KeywordsModalComponent,
    RecordsModalComponent,
    BlueDataModalComponent,
    OrangeDataModalComponent,
    OrangeDataModalComponent,
    BdilModalComponent,
    LeaderboardModalComponent,
    CompaniesModalComponent,
    RedDatasModalComponent,
    ProjectModalComponent,
    ProjectsListComponent,
    ProjectComponent,
    CustomersListComponent,
    KeywordsListComponent,
    RecordsListComponent,
    BlueDataListComponent,
    OrangeDataListComponent,
    BdilListComponent,
    LeaderboardListComponent,
    CompaniesListComponent,
    CompaniesList2Component,
    RedDatasListComponent,
    NewDataListComponent,
    NewDataLeaderboardComponent,
    NewdataModalComponent,
    UserDataListComponent,
    UserdataModalComponent
  ],
  imports: [
    CommonModule,
    InputsModule,
    InputUtilitiesModule,
    IconsModule,
    FormsModule,
    ButtonsModule,
    CardsModule
  ],
  exports: [ProjectsListComponent, ProjectComponent, CustomersListComponent, KeywordsListComponent, RecordsListComponent, BlueDataListComponent, OrangeDataListComponent, BdilListComponent, LeaderboardListComponent, CompaniesListComponent, CompaniesList2Component, RedDatasListComponent, NewDataListComponent, UserDataListComponent],
  providers: [],
  entryComponents: [
    ConfirmModalComponent,
    ConfirmSaveModalComponent,
    CustomersModalComponent,
    KeywordsModalComponent,
    RecordsModalComponent,
    BlueDataModalComponent,
    OrangeDataModalComponent,
    BdilModalComponent,
    LeaderboardModalComponent,
    CompaniesModalComponent,
    RedDatasModalComponent,
    ProjectModalComponent,
    NewdataModalComponent,
    UserdataModalComponent
  ]
})
export class SharedModule {}
