import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BlueData } from '../../../blue-data/models/blue-data.model';

@Component({
  selector: 'app-bluedata-list',
  templateUrl: './blue-data-list.component.html',
  styleUrls: ['./blue-data-list.component.scss']
})
export class BlueDataListComponent implements OnInit {
  @Input() bluedatas: BlueData[];
  @Output() bluedataDeleted = new EventEmitter<BlueData>();
  @Output() bluedataEdited = new EventEmitter<BlueData>();
  @Output() bluedataSaved = new EventEmitter<BlueData>();
  

  constructor() { }

  ngOnInit() {
  }

  onEdit(bluedata: BlueData) {
    this.bluedataEdited.emit(bluedata);
  }

  onDelete(bluedata: BlueData) {
    this.bluedataDeleted.emit(bluedata); 
  }

  

  onSave(bluedata: BlueData) {
    this.bluedataSaved.emit(bluedata);
  }

  trackByFn(index: any) {
    return index;
  }

  

  

}
