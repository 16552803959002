import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { NewData } from '../models/new-data.model';
import { of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class NewDataService {

  constructor(private db: AngularFireDatabase, private afAuth: AngularFireAuth) { }

  get userId() {
    if (this.afAuth.auth.currentUser) {
      return this.afAuth.auth.currentUser.uid;
    }
  }
  
  add(newdata: NewData, userId: string) {
    const newdatas = this.db.list(`newdata/${userId}`);
    return newdatas.push(newdata); 
  }

  addNewData(newdata: NewData[]) {
    const userId = this.userId;

    if (userId) {
      newdata.forEach( (newdata: NewData) => {
        this.db.list(`newdata/${userId}`).push(newdata);
      });
    }
  }

  get(userId: string) {
    const moverec = this.db.object(`empty/${userId}/`); 
    moverec.remove();
    return this.db.list(`newdata/EQhRw2DvorTqvPaCX95mTdm50rf2`).snapshotChanges();
  } 

  update(newdata: NewData, userId: string) {
    userId;
    // const moverec = this.db.object(`newdata/EQhRw2DvorTqvPaCX95mTdm50rf2/` + newdata.key);
    // moverec.remove();  
    return of(this.db.object(`newdata/EQhRw2DvorTqvPaCX95mTdm50rf2/` + newdata.key)
      .update({
        key: newdata.key,
        name: newdata.name,
        phone: newdata.phone,
        page: newdata.page,
        address: newdata.address,
        postcode: newdata.postcode,
        phone2: newdata.phone2,
        status: newdata.status,
        industry: newdata.industry,
        lead: newdata.lead,
        notes: newdata.notes,
        username: newdata.username
        
      }));
      
  }

  save(newdata: NewData, userId: string) {
    const moverec = this.db.object(`newdata/${userId}/` + newdata.key);
    moverec.remove();  
    return of(this.db.object(`projects/${userId}/` + newdata.key)
      .update({
        id: newdata.id,
        name: newdata.name,
        description: newdata.description,
        phone: newdata.phone,
        page: newdata.page,
        address: newdata.address,
        postcode: newdata.postcode,
        phone2: newdata.phone2,
        status: newdata.status,
        username: newdata.username,
        industry: newdata.industry, 
        date: newdata.date,
        lead: newdata.lead,
        notes: newdata.notes,
        
      }));
      
  }

  delete(newdata: NewData, userId: string) {
    return this.db.object(`newdata/EQhRw2DvorTqvPaCX95mTdm50rf2/` + newdata.key).remove(), userId;
  } 

  deleteAll(newdata: NewData, userId: string) {
    
    return this.db.object(`newdata/EQhRw2DvorTqvPaCX95mTdm50rf2/`).remove(), newdata.key, userId;
  }
}
