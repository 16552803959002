import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { UserData } from '../models/user-data.model';
import { of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  constructor(private db: AngularFireDatabase, private afAuth: AngularFireAuth) { }

  get userId() {
    if (this.afAuth.auth.currentUser) {
      return this.afAuth.auth.currentUser.uid;
    }
  }
  
  add(userdata: UserData, userId: string) {
    const userdatas = this.db.list(`newdata/${userId}`);
    return userdatas.push(userdata); 
  }

  addUserData(userdata: UserData[]) {
    const userId = this.userId;

    if (userId) {
      userdata.forEach( (userdata: UserData) => {
        this.db.list(`newdata/${userId}`).push(userdata);
      });
    }
  }

  get(userId: string) {
    const moverec = this.db.object(`empty/${userId}/`);
    moverec.remove();
    return this.db.list(`newdata/${userId}/`).snapshotChanges();
  } 

  update(userdata: UserData, userId: string) {
    const moverec = this.db.object(`newdata/${userId}/` + userdata.key);
    moverec.remove();  
    return of(this.db.object(`newdata/${userId}/` + userdata.key)
      .update({
        id: userdata.id,
        name: userdata.name,
        description: userdata.description,
        phone: userdata.phone,
        page: userdata.page,
        address: userdata.address,
        postcode: userdata.postcode,
        phone2: userdata.phone2,
        status: userdata.status,
        username: userdata.username,
        industry: userdata.industry,
        lead: userdata.lead
        
      }));
      
  }

  save(userdata: UserData, userId: string) {
    const moverec = this.db.object(`newdata/${userId}/` + userdata.key);
    moverec.remove();  
    return of(this.db.object(`newdata/${userId}/` + userdata.key)
      .update({
        id: userdata.id,
        name: userdata.name,
        description: userdata.description,
        phone: userdata.phone,
        page: userdata.page,
        address: userdata.address,
        postcode: userdata.postcode,
        phone2: userdata.phone2,
        status: userdata.status,
        username: userdata.username,
        industry: userdata.industry, 
        date: userdata.date,
        lead: userdata.lead
      }));
      
  }

  delete(userdata: UserData, userId: string) {
    return this.db.object(`newdata/${userId}/` + userdata.key).remove();
  }
}
