import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OrangeData } from '../../../orange-data/models/orange-data.model';

@Component({
  selector: 'app-orangedata-list',
  templateUrl: './orange-data-list.component.html',
  styleUrls: ['./orange-data-list.component.scss']
})
export class OrangeDataListComponent implements OnInit {
  @Input() orangedatas: OrangeData[];
  @Output() orangedataDeleted = new EventEmitter<OrangeData>();
  @Output() orangedataEdited = new EventEmitter<OrangeData>();
  @Output() orangedataSaved = new EventEmitter<OrangeData>();
  

  constructor() { }

  ngOnInit() {
  }

  onEdit(orangedata: OrangeData) {
    this.orangedataEdited.emit(orangedata);
  }

  onDelete(orangedata: OrangeData) {
    this.orangedataDeleted.emit(orangedata); 
  }

  

  onSave(orangedata: OrangeData) {
    this.orangedataSaved.emit(orangedata);
  }

  trackByFn(index: any) {
    return index;
  }

  

  

}
