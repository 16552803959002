<div class="row">
  <div class="col-12" style="color: #ef6572; margin-bottom: 10px;">
    <h2>Leads Found Today</h2>
  </div>
</div>

<div class="row">
  <!-- Card -->
<div class="card col-2 z-depth-1" style="margin-bottom: 18px; text-align:center; margin-right: 15px; margin-left: 16px;">

  <!-- Card image -->
  
  <!-- Card content -->
  <div class="card-body" *ngFor="let lead of leads.slice(-1); trackBy: trackByFn; let i = index;">

    <!-- Title -->
    <h4 class="card-title"><a>{{ lead.nameUserMegan }}</a></h4>
    <!-- Text -->
    <span style="font-size: 31px; background-color: #ef6572; color: #ffffff;" class="badge">{{ lead.todayLeadsMegan }}</span>
   

  </div>

</div>
<!-- Card -->
<!-- Card -->
<div class="card col-2 z-depth-1" style="margin-bottom: 18px; text-align:center; margin-right: 15px; margin-left: 16px;">

  <!-- Card image -->
  
  <!-- Card content -->
  <div class="card-body" *ngFor="let lead of leads.slice(-1); trackBy: trackByFn; let i = index;">

    <!-- Title -->
    <h4 class="card-title"><a>{{ lead.nameUserChris }}</a></h4>
    <!-- Text -->
    <span style="font-size: 31px; background-color: #ef6572; color: #ffffff;" class="badge">{{ lead.todayLeadsChris }}</span>
   

  </div>

</div>
<!-- Card -->
<!-- Card -->
<div class="card col-2 z-depth-1" style="margin-bottom: 18px; text-align:center; margin-right: 15px; margin-left: 16px;">

  <!-- Card image -->
  
  <!-- Card content -->
  <div class="card-body" *ngFor="let lead of leads.slice(-1); trackBy: trackByFn; let i = index;">

    <!-- Title -->
    <h4 class="card-title"><a>{{ lead.nameUserPhil }}</a></h4>
    <!-- Text -->
    <span style="font-size: 31px; background-color: #ef6572; color: #ffffff;" class="badge">{{ lead.todayLeadsPhil }}</span>
   

  </div>

</div>
<!-- Card -->
<!-- Card -->
<div class="card col-2 z-depth-1" style="margin-bottom: 18px; text-align:center; margin-right: 15px; margin-left: 16px;">

  <!-- Card image -->
  
  <!-- Card content -->
  <div class="card-body" *ngFor="let lead of leads.slice(-1); trackBy: trackByFn; let i = index;">

    <!-- Title -->
    <h4 class="card-title"><a>{{ lead.nameUserbecca }}</a></h4>
    <!-- Text -->
    <span style="font-size: 31px; background-color: #ef6572; color: #ffffff;" class="badge">{{ lead.todayLeadsbecca }}</span>
   

  </div>

</div>
<!-- Card -->
<!-- Card -->
<div class="card col-2 z-depth-1" style="margin-bottom: 18px; text-align:center; margin-right: 15px; margin-left: 16px;">

  <!-- Card image -->
  
  <!-- Card content -->
  <div class="card-body" *ngFor="let lead of leads.slice(-1); trackBy: trackByFn; let i = index;">

    <!-- Title -->
    <h4 class="card-title"><a>{{ lead.nameUserGabriella }}</a></h4>
    <!-- Text -->
    <span style="font-size: 31px; background-color: #ef6572; color: #ffffff;" class="badge">{{ lead.todayLeadsGabriella }}</span>
   

  </div>

</div>
<!-- Card -->
</div>










<div class="row">
  <div class="col-12" style="color: #33a9a1; margin-bottom: 10px;">
    <h2>Leads Found All</h2>
  </div>
</div>

<div class="row">
  <!-- Card -->
<div class="card col-2 z-depth-1" style="margin-bottom: 18px; text-align:center; margin-right: 15px; margin-left: 16px;">

  <!-- Card image -->
  
  <!-- Card content -->
  <div class="card-body" *ngFor="let lead of leads.slice(-1); trackBy: trackByFn; let i = index;">

    <!-- Title -->
    <h4 class="card-title"><a>{{ lead.nameUserMegan }}</a></h4>
    <!-- Text -->
    <span style="font-size: 31px; background-color: #33a9a1; color: #ffffff;" class="badge">{{ lead.allLeadsMegan }}</span>
   

  </div>

</div>
<!-- Card -->
<!-- Card -->
<div class="card col-2 z-depth-1" style="margin-bottom: 18px; text-align:center; margin-right: 15px; margin-left: 16px;">

  <!-- Card image -->
  
  <!-- Card content -->
  <div class="card-body" *ngFor="let lead of leads.slice(-1); trackBy: trackByFn; let i = index;">

    <!-- Title -->
    <h4 class="card-title"><a>{{ lead.nameUserChris }}</a></h4>
    <!-- Text -->
    <span style="font-size: 31px; background-color: #33a9a1; color: #ffffff;" class="badge">{{ lead.allLeadsChris }}</span>
   

  </div>

</div>
<!-- Card -->
<!-- Card -->
<div class="card col-2 z-depth-1" style="margin-bottom: 18px; text-align:center; margin-right: 15px; margin-left: 16px;">

  <!-- Card image -->
  
  <!-- Card content -->
  <div class="card-body" *ngFor="let lead of leads.slice(-1); trackBy: trackByFn; let i = index;">

    <!-- Title -->
    <h4 class="card-title"><a>{{ lead.nameUserPhil }}</a></h4>
    <!-- Text -->
    <span style="font-size: 31px; background-color: #33a9a1; color: #ffffff;" class="badge">{{ lead.allLeadsPhil }}</span>
   

  </div>

</div>
<!-- Card -->
<!-- Card -->
<div class="card col-2 z-depth-1" style="margin-bottom: 18px; text-align:center; margin-right: 15px; margin-left: 16px;">

  <!-- Card image -->
  
  <!-- Card content -->
  <div class="card-body" *ngFor="let lead of leads.slice(-1); trackBy: trackByFn; let i = index;">

    <!-- Title -->
    <h4 class="card-title"><a>{{ lead.nameUserbecca }}</a></h4>
    <!-- Text -->
    <span style="font-size: 31px; background-color: #33a9a1; color: #ffffff;" class="badge">{{ lead.allLeadsbecca }}</span>
   

  </div>

</div>
<!-- Card -->
<!-- Card -->
<div class="card col-2 z-depth-1" style="margin-bottom: 18px; text-align:center; margin-right: 15px; margin-left: 16px;">

  <!-- Card image -->
  
  <!-- Card content -->
  <div class="card-body" *ngFor="let lead of leads.slice(-1); trackBy: trackByFn; let i = index;">

    <!-- Title -->
    <h4 class="card-title"><a>{{ lead.nameUserGabriella }}</a></h4>
    <!-- Text -->
    <span style="font-size: 31px; background-color: #33a9a1; color: #ffffff;" class="badge">{{ lead.allLeadsGabriella }}</span>
   

  </div>

</div>
<!-- Card -->
</div>


<div class="z-depth-1 white">
  <tr *ngFor="let lead of leads.slice(-1); trackBy: trackByFn; let i = index;">
    
    <td><span style="margin-bottom: 12px; margin-top: 12px; margin-left: 10px; font-size: 31px; float: left;" class="badge badge-info">{{ leads.length }}</span></td> 
  </tr>
 

  

  <table class="table table-striped table-responsive-md btn-table" mdbTable borderless="true">
    <thead style="background-color: transparent">
      <tr>
        <th>Hashtag / Keyword</th>
        <!-- <th>Id</th> -->
        <th>Total records</th>
        <th>Percentage for total records</th>
        <th>Total found for Hashtag/Keyword</th>
        <th>Amount of Leads Found</th>
        <th>Leads per hashtag/keyword</th>
        <th>percentage of total leads</th>
        <!-- <th>Amount of Leads Found</th> -->
    
        <!-- <th>Total Lead Conversion</th> -->
       
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let lead of leads; trackBy: trackByFn; let i = index;">
        <td>{{ lead.name }}</td>
        <!-- <th scope="row">{{ record.id }}</th> --> 
        <!-- <td scope="row"><span style="margin-left: 10px; font-size: 20px; float: left;" class="badge rfb">{{ lead.hashAll / lead.pulledCount | percent:'1.2' }}</span></td> -->
        <td scope="row"><span style="margin-left: 10px; font-size: 20px; float: left;" class="badge rfb">{{ lead.pulledCount }}</span></td>
        <td scope="row"><span style="margin-left: 10px; font-size: 20px; float: left;" class="badge rfb">{{ lead.hashAll / lead.pulledCount | percent:'1.2' }}</span></td>
        <td scope="row"><span style="margin-left: 10px; font-size: 20px; float: left;" class="badge rfb">{{ lead.hashAll }}</span></td>
        <td scope="row"><span style="margin-left: 10px; font-size: 20px; float: left;" class="badge lfb">{{ lead.totalLeads}}</span></td>
        <td scope="row"><span style="margin-left: 10px; font-size: 20px; float: left;" class="badge lfb">{{ lead.leadCount }}</span></td>
        <td scope="row"><span style="margin-left: 10px; font-size: 20px; float: left;" class="badge lfb">{{ lead.leadCount / lead.hashAll | percent:'1.2'}}</span></td>
        <!-- <td ng-init="$parent.grandtotal  = grandtotal + x.rate"> {{ x.amount | number:2 }} </td> -->
        <!-- <td scope="row"><span style="margin-left: 10px; font-size: 20px; float: left;" class="badge lfb">{{ lead.leadCount / lead.hashAll | percent:'1.2' }}</span></td> -->
     
        <!-- <td scope="row">{{ lead.hashAll / leads.length}}%</td> -->
        
      
      </tr>
    </tbody>
  </table>

 
</div>
