import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Bdil } from '../../../bd-il/models/bd-il.model';

@Component({
  selector: 'app-bdil-list',
  templateUrl: './bd-il-list.component.html',
  styleUrls: ['./bd-il-list.component.scss']
})
export class BdilListComponent implements OnInit {
  @Input() bdils: Bdil[];
  @Output() bdilDeleted = new EventEmitter<Bdil>();
  @Output() bdilEdited = new EventEmitter<Bdil>();
  @Output() bdilSaved = new EventEmitter<Bdil>();
  

  constructor() { }

  ngOnInit() {
  }

  onEdit(bdil: Bdil) {
    this.bdilEdited.emit(bdil);
  }

  onDelete(bdil: Bdil) {
    this.bdilDeleted.emit(bdil); 
  }

  

  onSave(bdil: Bdil) {
    this.bdilSaved.emit(bdil);
  }

  trackByFn(index: any) {
    return index;
  }

  

  

}
