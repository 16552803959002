import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Leaderboard } from '../../../leaderboard/models/leaderboard.model';


@Component({
  selector: 'app-leaderboard-list',
  templateUrl: './leaderboard-list.component.html',
  styleUrls: ['./leaderboard-list.component.scss'],
})
export class LeaderboardListComponent implements OnInit {
  @Input() leads: Leaderboard[];
  @Output() leadDeleted = new EventEmitter<Leaderboard>();
  @Output() leadEdited = new EventEmitter<Leaderboard>();
  @Output() leadSaved = new EventEmitter<Leaderboard>();
  

  constructor() { }

  ngOnInit() {
  }

  onEdit(lead: Leaderboard) {
    this.leadEdited.emit(lead);
  }

  onDelete(lead: Leaderboard) {
    this.leadDeleted.emit(lead); 
  }

  

  onSave(lead: Leaderboard) {
    this.leadSaved.emit(lead);
  }

  trackByFn(index: any) {
    return index;
  }

  

  

  

}
