<div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">×</span>
      </button>
      <h4 class="modal-title w-100" id="myModalLabel">{{ heading }}</h4>
    </div>
    <div class="modal-body">
      <form #userdataForm="ngForm"> 
        <!-- <div class="md-form">
          <input type="text" id="id" name="id" class="form-control" [(ngModel)]="record.id" mdbInput mdbValidate [validateSuccess]="false" #id="ngModel" required>
          <label for="id">Id</label>
          <mdb-error *ngIf="id && id.invalid && (id.dirty || id.touched)">Id is required</mdb-error>
        </div> -->
  
        <div class="md-form">
          <input type="text" id="name" name="name" class="form-control" [(ngModel)]="userdata.name" mdbInput mdbValidate [validateSuccess]="false" #name="ngModel" required>
          <label for="name">Name</label>
          <mdb-error *ngIf="name && name.invalid && (name.dirty || name.touched)">Name is required</mdb-error>
        </div>
  
        <div class="md-form">
          <textarea type="text" id="address" name="address" class="md-textarea form-control" [(ngModel)]="userdata.address" mdbInput mdbValidate [validateSuccess]="false" #address="ngModel" required></textarea>
          <label for="address">Address</label>
          <mdb-error *ngIf="address && address.invalid && (address.dirty || address.touched)">Address is required</mdb-error>
        </div>
        <div class="md-form">
          <input type="text" id="phone" name="phone" class="form-control" [(ngModel)]="userdata.phone" mdbInput mdbValidate [validateSuccess]="false" #phone="ngModel" required>
          <label for="phone">Phone Number</label>
          <mdb-error *ngIf="phone && phone.invalid && (phone.dirty || phone.touched)">Phone Number is required</mdb-error>
        </div>
  
  
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" mdbBtn color="white" class="black-text waves-light" aria-label="Close" (click)="modalRef.hide()"
        mdbWavesEffect>Close</button>
      <button type="button" mdbBtn color="red" class="relative waves-light" mdbWavesEffect (click)="onSave()">Save</button>
    </div>
  </div>
  