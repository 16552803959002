<mdb-card>
  <!--Card image-->
  <mdb-card-img *ngIf="project.photoUrl" src="{{ project.photoUrl }}" alt="Card image cap" class="waves-light"
    mdbWavesEffect></mdb-card-img>
  <!-- <mdb-card-img *ngIf="!project.photoUrl" src="https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(97).jpg" alt="Card image cap"></mdb-card-img> -->
  <!--Card content-->
  <mdb-card-body>

    <!--Title-->
    <mdb-card-title>
      <h4>{{ project.title }}</h4>
    </mdb-card-title>

    <!--Text-->
    <mdb-card-text> {{ project.description }}
    </mdb-card-text>

    <div class="text-left">
      <a *ngIf="editable" mdbBtn color="primary" mdbWavesEffect (click)="onEdit()">Edit</a>
      <a mdbBtn class="red lighten-1 white-text" mdbWavesEffect (click)="onDelete()">Delete</a>
    </div>
  </mdb-card-body>
</mdb-card>