import { Component, OnInit, ViewChild } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { BlueData } from '../../../blue-data/models/blue-data.model';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-bluedata-modal',
  templateUrl: './blue-data-modal.component.html',
  styleUrls: ['./blue-data-modal.component.scss']
})
export class BlueDataModalComponent implements OnInit {
  @ViewChild('bluedataForm', { static: true }) bluedataForm: NgForm;

  heading: string;
  bluedata: BlueData = {};

  bluedataData: Subject<BlueData> = new Subject();

  constructor(public modalRef: MDBModalRef) { } 

  ngOnInit() { 
  }

  onSave() {
    if (this.bluedataForm.valid) {
      this.bluedataData.next(this.bluedata);
    this.modalRef.hide();
    } else {
      const controls = this.bluedataForm.controls;
      Object.keys(controls).forEach( controlName => controls[controlName].markAsTouched());
    }
  }

}
