import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Record } from '../../../records/models/records.model';


@Component({
  selector: 'app-records-list',
  templateUrl: './records-list.component.html',
  styleUrls: ['./records-list.component.scss']
})
export class RecordsListComponent implements OnInit {
  @Input() records: Record[];
  @Output() recordDeleted = new EventEmitter<Record>();
  @Output() recordEdited = new EventEmitter<Record>();
  @Output() recordSaved = new EventEmitter<Record>();
  

  constructor() { }

  ngOnInit() {
  }

  onEdit(record: Record) {
    this.recordEdited.emit(record);
  }

  onDelete(record: Record) {
    this.recordDeleted.emit(record); 
  }

  

  onSave(record: Record) {
    this.recordSaved.emit(record);
  }

  trackByFn(index: any) {
    return index;
  }

  

  

}
