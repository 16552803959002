<div class="z-depth-1">
  <tr *ngFor="let record of records.slice(1, 2).reverse(); trackBy: trackByFn; let i = index;">
    
    <td><span style="margin-bottom: 12px; margin-top: 12px; margin-left: 10px; font-size: 31px; float: left;" class="badge badge-info">{{ records.length }}</span></td> 
  
  </tr>
  
  <table class="table table-striped table-responsive-md btn-table" mdbTable borderless="true">
    <thead style="background-color: transparent">
      <tr>
        <th>Lead Count</th>
        <!-- <th>Id</th> -->
        <th>Company Name</th>
        
        <th>Page</th>
        <th>Post</th>
        <th>Hashtag</th>
        <th>Date Found</th>
        <th>Actions</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let record of records; trackBy: trackByFn; let i = index;">
        <td>{{ i + 1 }}</td>
        <!-- <th scope="row">{{ record.id }}</th> --> 
        <td scope="row">{{ record.name }}</td>
        
        
        <td><a href="{{ record.page }}" class="viewLink" target="__blank">View Page</a></td>
        <td><a href="{{ record.description }}" class="viewLink" target="__blank">View Post</a></td>
        <th>{{ record.hashtag }}</th>
        <th>{{ record.date }}</th>
        <td>
          <!-- <a (click)="onEdit(record)" class="mr-2"><mdb-icon fas icon="edit"></mdb-icon></a> -->
          <a (click)="onDelete(record)" class="mr-2"><mdb-icon fas icon="trash"></mdb-icon></a>
          <a (click)="onSave(record)" class="mr-2"><mdb-icon fas icon="save"></mdb-icon></a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
