
<div class="container full-page-container">
  <div class="row h-100 justify-content-center align-items-center">
    <div class="col-md-8 text-center fadeIn animated">
      <h1 class="app-heading mb-4">WELCOME</h1>
      <button mdbBtn style="background-color: #ec44e2; color: white;" block="true" class="my-4 waves-effect z-depth-0"
              mdbWavesEffect routerLink="/login">Account Login</button>
     
    </div>
  </div>
</div>