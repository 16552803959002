import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, empty } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../reducers/index';
import { ProjectsService } from '../../projects/services/projects.service';
import { CustomersService } from '../../customers/services/customers.service';
import { KeywordsService } from '../../keywords/services/keywords.service';
import { RecordsService } from '../../records/services/records.service';
// import { LeaderboardService } from '../../leaderboard/services/leaderboard.service';
import { CompaniesService } from '../../companies-house/services/companies-house.service';
import { RedDatasService } from '../../red-data/services/red-data.service';
import { NewDataService } from '../../new-data-found/services/new-data.service';
import { UserDataService } from '../../user-data/services/user-data.service';
import { getUser } from '../../auth/store/auth.selectors';
import { switchMap, take } from 'rxjs/operators';
import { Customer } from '../../customers/models/customer.model';
import { Keyword } from '../../keywords/models/keywords.model';
// import { Leaderboard } from '../../leaderboard/models/leaderboard.model';
import { Record } from '../../records/models/records.model';
import { Company } from '../../companies-house/models/companies-house.model';
import { NewData } from '../../new-data-found/models/new-data.model';
import { RedData } from '../../red-data/models/red-data.model';
import { UserData } from '../../user-data/models/user-data.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  projectsSub: Subscription;
  projects = [
    {
      title: 'Project 1',
      description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
      photoUrl:
        'https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(97).jpg'
    },
    {
      title: 'Project 2',
      description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
      photoUrl:
        'https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(98).jpg'
    },
    {
      title: 'Project 3',
      description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
      photoUrl:
        'https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(99).jpg'
    },
    {
      title: 'Project 4',
      description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
      photoUrl:
        'https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(95).jpg'
    }
  ];

  customersSub: Subscription;
  customers: Customer[] = [
    {
      
    },
    {
      
    },
    {
      
    },
    {
      
    },
    {
      
    }
  ];

  keywordsSub: Subscription;
  keywords: Keyword[] = [
    {
      
    },
    {
      
    },
    {
    },
    {
      
    },
    {
      
    }
  ];

  recordsSub: Subscription;
  records: Record[] = []

  companiesSub: Subscription;
  companies: Company[] = [ 
    {
      
    },
    {
     
    },
    {
      
    },
    {
      
    },
    {
      
    }
  ];

  reddatasSub: Subscription;
  reddatas: RedData[] = [
    {
      
    },
    {
     
    },
    {
      
    },
    {
      
    },
    {
      
    }
  ];

  newdataSub: Subscription;
  newdata: NewData[] = [
    {
      
    },
    {
      
    },
    {
      
    },
    {
      
    },
    {
      
    }
  ];

  userdataSub: Subscription;
  userdata: UserData[] = [
    {
      
    },
    {
      
    },
    {
      
    },
    {
      
    },
    {
      
    }
  ];


  constructor(
    private store: Store<AppState>,
    private projectsService: ProjectsService,
    private customersService: CustomersService,
    private keywordsService: KeywordsService,
    private recordsService: RecordsService,
    private companiesService: CompaniesService,
    private reddatasService: RedDatasService,
    private newdataService: NewDataService,
    private UserDataService: UserDataService
  ) {}

  ngOnInit() {
    this.initProjects();
    this.initCustomers();
    this.initKeywords();
    this.initRecords();
    this.initCompanies();
    this.initRedDatas();
    this.initNewData();
    this.initUserData();
  }

  ngOnDestroy() {
    if (this.projectsSub) {
      this.projectsSub.unsubscribe();
    }

    if (this.customersSub) {
      this.customersSub.unsubscribe();
    }

    if (this.keywordsSub) {
      this.keywordsSub.unsubscribe();
    }

    if (this.recordsSub) {
      this.recordsSub.unsubscribe();
    }

    if (this.companiesSub) {
      this.companiesSub.unsubscribe();
    }

    if (this.reddatasSub) {
      this.reddatasSub.unsubscribe();
    }

    if (this.newdataSub) {
      this.newdataSub.unsubscribe();
    }

    if (this.userdataSub) {
      this.userdataSub.unsubscribe();
    }
  }

  initProjects() {
    this.projectsSub = this.store
      .pipe(
        select(getUser),
        switchMap((user: any) => {
          if (user) {
            return this.projectsService.get(user.uid);
          } else {
            return empty();
          }
        }),
        take(1)
      )
      .subscribe(projects => {
        if (projects.length === 0) {
          this.projectsService.addProjects(this.projects);
        }
      });
  }

  initCustomers() {
    this.customersSub = this.store
      .pipe(
        select(getUser),
        switchMap((user: any) => {
          if (user) {
            return this.customersService.get(user.uid);
          } else {
            return empty();
          }
        }),
        take(1)
      )
      .subscribe(customers => {
        if (customers.length === 0) {
          this.customersService.addCustomers(this.customers);
        }
      });
  }

  initKeywords() {
    this.keywordsSub = this.store
      .pipe(
        select(getUser),
        switchMap((user: any) => {
          if (user) {
            return this.keywordsService.get(user.uid);
          } else {
            return empty();
          }
        }),
        take(1)
      )
      .subscribe(keywords => {
        if (keywords.length === 0) {
          this.keywordsService.addKeywords(this.keywords);
        }
      });
  }

  initRecords() {
    this.recordsSub = this.store
      .pipe(
        select(getUser),
        switchMap((user: any) => {
          if (user) {
            return this.recordsService.get(user.uid);
          } else {
            return empty();
          }
        }),
        take(1)
      )
      .subscribe(records => {
        if (records.length === 0) {
          this.recordsService.addRecords(this.records);
        }
      });
  }

  initCompanies() {
    this.companiesSub = this.store
      .pipe(
        select(getUser),
        switchMap((user: any) => {
          if (user) {
            return this.companiesService.get(user.uid);
          } else {
            return empty();
          }
        }),
        take(1)
      )
      .subscribe(companies => {
        if (companies.length === 0) {
          this.companiesService.addCompanies(this.companies);
        }
      });
  }
  

  initRedDatas() {
    this.reddatasSub = this.store
      .pipe(
        select(getUser),
        switchMap((user: any) => {
          if (user) {
            return this.reddatasService.get(user.uid);
          } else {
            return empty();
          }
        }),
        take(1)
      )
      .subscribe(reddatas => {
        if (reddatas.length === 0) {
          this.reddatasService.addRedDatas(this.reddatas);
        }
      });
  }

  initNewData() {
    this.newdataSub = this.store
      .pipe(
        select(getUser),
        switchMap((user: any) => {
          if (user) {
            return this.newdataService.get(user.uid);
          } else {
            return empty();
          }
        }),
        take(1)
      )
      .subscribe(newdata => {
        if (newdata.length === 0) {
          this.newdataService.addNewData(this.newdata);
        }
      });
  }


  initUserData() {
    this.userdataSub = this.store
      .pipe(
        select(getUser),
        switchMap((user: any) => {
          if (user) {
            return this.UserDataService.get(user.uid);
          } else {
            return empty();
          }
        }),
        take(1)
      )
      .subscribe(userdata => {
        if (userdata.length === 0) {
          this.UserDataService.addUserData(this.userdata);
        }
      });
  }
}
 