import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../app/reducers';

import * as fromAdmin from '../../store/admin.actions';
import { Observable } from 'rxjs';
import {
  getUsersList,
  getUserProjects,
  getSelectedUser,
  getUsersListLoading,
  getUserProjectsLoading,
  getUserCustomers,
  getUserKeywords,
  getUserKeywordsLoading,
  getUserCustomersLoading,
  getUserRecords,
  getUserRecordsLoading,
  getUserBlueData,
  getUserBlueDataLoading,
  getUserOrangeData,
  getUserOrangeDataLoading,
  getUserBdil,
  getUserBdilLoading,
  getUserLeads,
  getUserLeadsLoading,
  getUserCompanies,
  getUserRedDatas,
  getUserCompaniesLoading,
  getUserRedDatasLoading,
  getUserNewData,
  getUserNewDataLoading,
  getUserUserData,
  getUserUserDataLoading

} from '../../store/admin.selectors';
import { Project } from '../../../projects/models/project.model';
import { User } from '../../../auth/models/user.model';
import { map, delay, take } from 'rxjs/operators';
import { MDBModalService, MDBModalRef } from 'angular-bootstrap-md';
import { ConfirmModalComponent } from '../../../shared/components/confirm-modal/confirm-modal.component';
import { ConfirmSaveModalComponent } from '../../../shared/components/confirm-save-modal/confirm-save-modal.component'
import { Customer } from '../../../customers/models/customer.model';
import { Record } from '../../../records/models/records.model';
import { BlueData } from '../../../blue-data/models/blue-data.model';
import { OrangeData } from '../../../orange-data/models/orange-data.model';
import { Bdil } from '../../../bd-il/models/bd-il.model';
import { Leaderboard } from '../../../leaderboard/models/leaderboard.model';
import { Company } from '../../../companies-house/models/companies-house.model';
import { RedData } from '../../../red-data/models/red-data.model';
import { Keyword } from '../../../keywords/models/keywords.model';
import { NewData } from '../../../new-data-found/models/new-data.model';
import { UserData } from '../../../user-data/models/user-data.model';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  constructor(
    private store: Store<AppState>,
    private modalService: MDBModalService
  ) {}

  users$: Observable<any>;
  userProjects$: Observable<Project[]>;
  userCustomers$: Observable<Customer[]>;
  userRecords$: Observable<Record[]>;
  userBlueData$: Observable<BlueData[]>;
  userOrangeData$: Observable<OrangeData[]>;
  userBdil$: Observable<Bdil[]>;
  userLeads$: Observable<Leaderboard[]>;
  userCompanies$: Observable<Company[]>;
  userRedDatas$: Observable<RedData[]>;
  userNewData$: Observable<NewData[]>;
  userUserData$: Observable<UserData[]>;
  userKeywords$: Observable<Keyword[]>;
  usersListLoading$: Observable<boolean>;
  userProjectsLoading$: Observable<boolean>;
  userCustomersLoading$: Observable<boolean>;
  userKeywordsLoading$: Observable<boolean>;
  userRecordsLoading$: Observable<boolean>;
  userBlueDataLoading$: Observable<boolean>;
  userOrangeDataLoading$: Observable<boolean>;
  userBdilLoading$: Observable<boolean>;
  userLeadsLoading$: Observable<boolean>;
  userCompaniesLoading$: Observable<boolean>;
  userRedDatasLoading$: Observable<boolean>;
  userNewDataLoading$: Observable<boolean>;
  userUserDataLoading$: Observable<boolean>;
  selectedUser$: Observable<any>;
  selectedUser: any;
  uid: any;

  private modalRef: MDBModalRef;

  modalConfig = {
    class: 'modal-dialog-centered'
  };

  ngOnInit() {
    this.users$ = this.store.pipe(
      select(getUsersList),
      delay(0),
      map((users: User[]) => {
        if (!users || (users && users.length === 0)) {
          this.store.dispatch(new fromAdmin.GetUsersList());
        }
        return users;
      })
    );
    this.usersListLoading$ = this.store.select(getUsersListLoading);
    this.userProjectsLoading$ = this.store.select(getUserProjectsLoading);
    this.userCustomersLoading$ = this.store.select(getUserCustomersLoading);
    this.userKeywordsLoading$ = this.store.select(getUserKeywordsLoading);
    this.userRecordsLoading$ = this.store.select(getUserRecordsLoading);
    this.userBlueDataLoading$ = this.store.select(getUserBlueDataLoading);
    this.userOrangeDataLoading$ = this.store.select(getUserOrangeDataLoading);
    this.userBdilLoading$ = this.store.select(getUserBdilLoading);
    this.userLeadsLoading$ = this.store.select(getUserLeadsLoading);
    this.userCompaniesLoading$ = this.store.select(getUserCompaniesLoading);
    this.userRedDatasLoading$ = this.store.select(getUserRedDatasLoading);
    this.userNewDataLoading$ = this.store.select(getUserNewDataLoading);
    this.userUserDataLoading$ = this.store.select(getUserUserDataLoading);
  }

  onUserSelect(user: any) {
    this.uid = user.uid;
    this.selectedUser = user;
    this.selectedUser$ = this.store.select(getSelectedUser, user.uid);
    this.userProjects$ = this.store.select(getUserProjects, user.uid).pipe(
      map(projects => {
        if (projects && projects.length !== 0) {
          return projects;
        } else {
          return null;
        }
      })
    );

    this.userCustomers$ = this.store.select(getUserCustomers, user.uid).pipe(
      map(customers => {
        if (customers && customers.length !== 0) {
          return customers;
        } else {
          return null;
        }
      })
    );

    this.userKeywords$ = this.store.select(getUserKeywords, user.uid).pipe(
      map(keywords => {
        if (keywords && keywords.length !== 0) {
          return keywords;
        } else {
          return null;
        }
      })
    );

    this.userRecords$ = this.store.select(getUserRecords, user.uid).pipe(
      map(records => {
        if (records && records.length !== 0) {
          return records;
        } else {
          return null;
        }
      })
    );

    this.userBlueData$ = this.store.select(getUserBlueData, user.uid).pipe(
      map(bluedata => {
        if (bluedata && bluedata.length !== 0) {
          return bluedata;
        } else {
          return null;
        }
      })
    );

    this.userOrangeData$ = this.store.select(getUserOrangeData, user.uid).pipe(
      map(orangedata => {
        if (orangedata && orangedata.length !== 0) {
          return orangedata;
        } else {
          return null;
        }
      })
    );

    this.userBdil$ = this.store.select(getUserBdil, user.uid).pipe(
      map(bdil => {
        if (bdil && bdil.length !== 0) {
          return bdil;
        } else {
          return null;
        }
      })
    );

    this.userLeads$ = this.store.select(getUserLeads, user.uid).pipe(
      map(leads => {
        if (leads && leads.length !== 0) {
          return leads;
        } else {
          return null;
        }
      })
    );

    this.userCompanies$ = this.store.select(getUserCompanies, user.uid).pipe(
      map(companies => {
        if (companies && companies.length !== 0) {
          return companies;
        } else {
          return null;
        }
      })
    );


    this.userRedDatas$ = this.store.select(getUserRedDatas, user.uid).pipe(
      map(reddatas => {
        if (reddatas && reddatas.length !== 0) {
          return reddatas;
        } else {
          return null;
        }
      })
    );

    this.userNewData$ = this.store.select(getUserNewData, user.uid).pipe(
      map(newdata => {
        if (newdata && newdata.length !== 0) {
          return newdata;
        } else {
          return null;
        }
      })
    );

    this.userUserData$ = this.store.select(getUserUserData, user.uid).pipe(
      map(userdata => {
        if (userdata && userdata.length !== 0) {
          return userdata;
        } else {
          return null;
        }
      })
    );
  }

  onProjectsLoad() {
    this.store.dispatch(new fromAdmin.GetUserProjects({ uid: this.uid }));
  }

  onCustomersLoad() {
    this.store.dispatch(new fromAdmin.GetUserCustomers({ uid: this.uid }));
  }

  onKeywordsLoad() {
    this.store.dispatch(new fromAdmin.GetUserKeywords({uid: this.uid}));
  }

  onRecordsLoad() {
    this.store.dispatch(new fromAdmin.GetUserRecords({ uid: this.uid }));
  }

  onBlueDataLoad() {
    this.store.dispatch(new fromAdmin.GetUserBlueData({ uid: this.uid }));
  }

  onOrangeDataLoad() {
    this.store.dispatch(new fromAdmin.GetUserOrangeData({ uid: this.uid }));
  }

  onBdilLoad() {
    this.store.dispatch(new fromAdmin.GetUserBdil({ uid: this.uid }));
  }

  onLeadsLoad() {
    this.store.dispatch(new fromAdmin.GetUserLeads({ uid: this.uid }));
  }

  onCompaniesLoad() {
    this.store.dispatch(new fromAdmin.GetUserCompanies({ uid: this.uid }));
  }

  onRedDatasLoad() {
    this.store.dispatch(new fromAdmin.GetUserRedDatas({ uid: this.uid }));
  }

  onNewDataLoad() {
    this.store.dispatch(new fromAdmin.GetUserNewData({ uid: this.uid }));
  }
  
  onDetailsClose() {
    this.selectedUser = null;
  }

  openProjectConfirmModal(project: Project) {
    this.modalRef = this.modalService.show(
      ConfirmModalComponent,
      this.modalConfig
    );

    this.modalRef.content.confirmation
      .pipe(take(1))
      .subscribe((confirmation: boolean) => {
        if (confirmation) {
          this.store.dispatch(
            new fromAdmin.DeleteUserProject({
              userId: this.selectedUser.key,
              projectId: project.key
            })
          );
        }
      });
  }

  openCustomerConfirmModal(customer: Customer) {
    this.modalRef = this.modalService.show(
      ConfirmModalComponent,
      this.modalConfig
    );

    this.modalRef.content.confirmation
      .pipe(take(1))
      .subscribe((confirmation: boolean) => {
        if (confirmation) {
          this.store.dispatch(
            new fromAdmin.DeleteUserCustomer({
              userId: this.selectedUser.key,
              customerId: customer.key
            })
          );
        }
      });
  }

  onCustomerDelete(customer: Customer) {
    this.openCustomerConfirmModal(customer);
  }

  openKeywordsConfirmModal(keyword: Keyword) {
    this.modalRef = this.modalService.show(
      ConfirmModalComponent,
      this.modalConfig
    );

    this.modalRef.content.confirmation
      .pipe(take(1))
      .subscribe((confirmation: boolean) => {
        if (confirmation) {
          this.store.dispatch(
            new fromAdmin.DeleteUserKeyword({
              userId: this.selectedUser.key,
              keywordId: keyword.key
            })
          );
        }
      });
  }

  onKeywordDelete(keyword: Keyword) {
    this.openCustomerConfirmModal(keyword);
  }

  openRecordConfirmModal(record: Record) {
    this.modalRef = this.modalService.show(
      ConfirmModalComponent,
      this.modalConfig
    );

    

    this.modalRef.content.confirmation
      .pipe(take(1))
      .subscribe((confirmation: boolean) => {
        if (confirmation) {
          this.store.dispatch(
            new fromAdmin.DeleteUserRecord({
              userId: this.selectedUser.key,
              recordId: record.key
            })
          );
        }
      });
  }
  

  openRecordConfirmSaveModal(record: Record) {
    this.modalRef = this.modalService.show(
      ConfirmSaveModalComponent,
      this.modalConfig
    );

    

    this.modalRef.content.confirmation
      .pipe(take(1))
      .subscribe((confirmation: boolean) => {
        if (confirmation) {
          this.store.dispatch(
            new fromAdmin.SaveUserRecord({
              userId: this.selectedUser.key,
              recordId: record.key 
            })
          );
        }
      });
  }


  onRecordDelete(record: Record) {
    this.openRecordConfirmModal(record);
  }

  onRecordSave(record: Record) {
    this.openRecordConfirmSaveModal(record);
  }


  openBlueDataConfirmModal(bluedata: BlueData) {
    this.modalRef = this.modalService.show(
      ConfirmModalComponent,
      this.modalConfig
    );

    

    this.modalRef.content.confirmation
      .pipe(take(1))
      .subscribe((confirmation: boolean) => {
        if (confirmation) {
          this.store.dispatch(
            new fromAdmin.DeleteUserBlueData({
              userId: this.selectedUser.key,
              bluedataId: bluedata.key
            })
          );
        }
      });
  }
  

  openBlueDataConfirmSaveModal(bluedata: BlueData) {
    this.modalRef = this.modalService.show(
      ConfirmSaveModalComponent,
      this.modalConfig
    );

    

    this.modalRef.content.confirmation
      .pipe(take(1))
      .subscribe((confirmation: boolean) => {
        if (confirmation) {
          this.store.dispatch(
            new fromAdmin.SaveUserBlueData({
              userId: this.selectedUser.key,
              bluedataId: bluedata.key 
            })
          );
        }
      });
  }


  onBlueDataDelete(bluedata: BlueData) {
    this.openBlueDataConfirmModal(bluedata);
  }

  onBlueDataSave(bluedata: BlueData) {
    this.openBlueDataConfirmSaveModal(bluedata);
  }



  openBdilConfirmModal(bdil: Bdil) {
    this.modalRef = this.modalService.show(
      ConfirmModalComponent,
      this.modalConfig
    );

    

    this.modalRef.content.confirmation
      .pipe(take(1))
      .subscribe((confirmation: boolean) => {
        if (confirmation) {
          this.store.dispatch(
            new fromAdmin.DeleteUserBdil({
              userId: this.selectedUser.key,
              bdilId: bdil.key
            })
          );
        }
      });
  }
  

  openBdilConfirmSaveModal(bdil: Bdil) {
    this.modalRef = this.modalService.show(
      ConfirmSaveModalComponent,
      this.modalConfig
    );

    

    this.modalRef.content.confirmation
      .pipe(take(1))
      .subscribe((confirmation: boolean) => {
        if (confirmation) {
          this.store.dispatch(
            new fromAdmin.SaveUserBdil({
              userId: this.selectedUser.key,
              bdilId: bdil.key 
            })
          );
        }
      });
  }


  onBdilDelete(bdil: Bdil) {
    this.openBdilConfirmModal(bdil);
  }

  onBdilSave(bdil: Bdil) {
    this.openBdilConfirmSaveModal(bdil);
  }

  openOrangeDataConfirmModal(orangedata: OrangeData) {
    this.modalRef = this.modalService.show(
      ConfirmModalComponent,
      this.modalConfig
    );

    

    this.modalRef.content.confirmation
      .pipe(take(1))
      .subscribe((confirmation: boolean) => {
        if (confirmation) {
          this.store.dispatch(
            new fromAdmin.DeleteUserOrangeData({
              userId: this.selectedUser.key,
              orangedataId: orangedata.key
            })
          );
        }
      });
  }
  

  openOrangeDataConfirmSaveModal(orangedata: OrangeData) {
    this.modalRef = this.modalService.show(
      ConfirmSaveModalComponent,
      this.modalConfig
    );

    

    this.modalRef.content.confirmation
      .pipe(take(1))
      .subscribe((confirmation: boolean) => {
        if (confirmation) {
          this.store.dispatch(
            new fromAdmin.SaveUserOrangeData({
              userId: this.selectedUser.key,
              orangedataId: orangedata.key 
            })
          );
        }
      });
  }


  onOrangeDataDelete(orangedata: OrangeData) {
    this.openOrangeDataConfirmModal(orangedata);
  }

  onOrangeDataSave(orangedata: OrangeData) {
    this.openOrangeDataConfirmSaveModal(orangedata);
  }

  onLeadsSave(lead: Leaderboard) {
    this.openRecordConfirmSaveModal(lead);
  }


  openRedDataConfirmModal(reddata: RedData) {
    this.modalRef = this.modalService.show(
      ConfirmModalComponent,
      this.modalConfig
    );

    

    this.modalRef.content.confirmation
      .pipe(take(1))
      .subscribe((confirmation: boolean) => {
        if (confirmation) {
          this.store.dispatch(
            new fromAdmin.DeleteUserRedData({
              userId: this.selectedUser.key,
              reddataId: reddata.key
            })
          );
        }
      });
  }

  openRedDataConfirmSaveModal(reddata: RedData) {
    this.modalRef = this.modalService.show(
      ConfirmSaveModalComponent,
      this.modalConfig
    );

    

    this.modalRef.content.confirmation
      .pipe(take(1))
      .subscribe((confirmation: boolean) => {
        if (confirmation) {
          this.store.dispatch(
            new fromAdmin.SaveUserRedData({
              userId: this.selectedUser.key,
              reddataId: reddata.key 
            })
          );
        }
      });
  }


  onRedDataDelete(reddata: RedData) {
    this.openCompanyConfirmModal(reddata);
  }

  onRedDataSave(reddata: RedData) {
    this.openRedDataConfirmSaveModal(reddata);
  }


  openCompanyConfirmModal(company: Company) {
    this.modalRef = this.modalService.show(
      ConfirmModalComponent,
      this.modalConfig
    );

    

    this.modalRef.content.confirmation
      .pipe(take(1))
      .subscribe((confirmation: boolean) => {
        if (confirmation) {
          this.store.dispatch(
            new fromAdmin.DeleteUserCompany({
              userId: this.selectedUser.key,
              companyId: company.key
            })
          );
        }
      });
  }

  openCompanyConfirmSaveModal(company: Company) {
    this.modalRef = this.modalService.show(
      ConfirmSaveModalComponent,
      this.modalConfig
    );

    

    this.modalRef.content.confirmation
      .pipe(take(1))
      .subscribe((confirmation: boolean) => {
        if (confirmation) {
          this.store.dispatch(
            new fromAdmin.SaveUserCompany({
              userId: this.selectedUser.key,
              companyId: company.key 
            })
          );
        }
      });
  }


  onCompanyDelete(company: Company) {
    this.openCompanyConfirmModal(company);
  }

  onCompanySave(company: Company) {
    this.openCompanyConfirmSaveModal(company);
  }


  onProjectDelete(project: Project) {
    this.openProjectConfirmModal(project);
  }

  addAdminPrivileges(user: any) {
    this.store.dispatch(new fromAdmin.AddAdminPrivileges({ userId: user.key }));
  }

  removeAdminPrivileges(user: any) {
    this.store.dispatch(
      new fromAdmin.RemoveAdminPrivileges({ userId: user.key })
    );
  }
}
