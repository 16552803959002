<div class="z-depth-1">
  <tr *ngFor="let reddata of reddatas.slice(1, 2).reverse(); trackBy: trackByFn; let i = index;">
    <td> <button mdbBtn style="float: right;" class="redb white-text" (click)="onDeleteAll(reddata)">Delete All</button> </td> 
    <td><span style="margin-bottom: 6px; font-size: 31px; float: left;" class="badge badge-info">{{ reddatas.length }}</span></td> 
  
  </tr>
  <table class="table table-striped table-responsive-md btn-table" mdbTable borderless="true">
    <thead style="background-color: transparent; color: #a4599c;">
      <tr>
        <!-- <th>Id</th> -->
        <!-- <th>Company Number</th> -->
        <th>Lead Count</th>
        <th>Company Name</th>
        <th>Address</th>
        <th>SIC</th>
        <th>DATE</th>
        <th>CH</th>
        <th>Google</th>
        <th>Actions</th>
      </tr>
    </thead> 

    <tbody>
      <tr *ngFor="let reddata of reddatas; trackBy: trackByFn; let i = index;">
        
        <!-- <th scope="row">{{ record.id }}</th> -->  
        <!-- <td scope="row">{{ company.id }}</td> -->
        <td>{{ i + 1 }}</td>
        <td scope="row">{{ reddata.name }}</td>
        <td>{{ reddata.address }}</td>
        <th>{{ reddata.sic }}</th>
        <th>{{ reddata.date }}</th>
        <td><a href="{{ reddata.page }}" target="_blank" class="viewLink">View</a></td>
        <td><a href="{{ reddata.description }}" target="_blank" class="viewLink">View</a></td>
       
        <td>
          <!-- <a (click)="onEdit(record)" class="mr-2"><mdb-icon fas icon="edit"></mdb-icon></a> -->
          <a (click)="onDelete(reddata)" class="mr-2"><mdb-icon fas icon="trash"></mdb-icon></a>
          <a (click)="onSave(reddata)" class="mr-2"><mdb-icon fas icon="save"></mdb-icon></a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
