import { Component, OnInit, ViewChild } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { OrangeData } from '../../../orange-data/models/orange-data.model';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-orangedata-modal',
  templateUrl: './orange-data-modal.component.html',
  styleUrls: ['./orange-data-modal.component.scss']
})
export class OrangeDataModalComponent implements OnInit {
  @ViewChild('orangedataForm', { static: true }) orangedataForm: NgForm;

  heading: string;
  orangedata: OrangeData = {};

  orangedataData: Subject<OrangeData> = new Subject();

  constructor(public modalRef: MDBModalRef) { } 

  ngOnInit() { 
  }

  onSave() {
    if (this.orangedataForm.valid) {
      this.orangedataData.next(this.orangedata);
    this.modalRef.hide();
    } else {
      const controls = this.orangedataForm.controls;
      Object.keys(controls).forEach( controlName => controls[controlName].markAsTouched());
    }
  }

}
