<div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">×</span>
      </button>
      <h4 class="modal-title w-100" id="myModalLabel">{{ heading }}</h4>
    </div>
    <div class="modal-body">
      <form #newdataForm="ngForm"> 
        <!-- <div class="md-form">
          <input type="text" id="id" name="id" class="form-control" [(ngModel)]="record.id" mdbInput mdbValidate [validateSuccess]="false" #id="ngModel" required>
          <label for="id">Id</label>
          <mdb-error *ngIf="id && id.invalid && (id.dirty || id.touched)">Id is required</mdb-error>
        </div> -->
  
        <div class="md-form">
          <input type="text" id="name" name="name" class="form-control" [(ngModel)]="newdata.name" mdbInput mdbValidate [validateSuccess]="false" #name="ngModel" required>
          <label for="name">Company Name</label>
   
        </div>
  
        <div class="md-form">
          <textarea type="text" id="address" name="address" class="md-textarea form-control" [(ngModel)]="newdata.address" mdbInput mdbValidate [validateSuccess]="false" #address="ngModel" required></textarea>
          <label for="address">Address</label>
          
        </div>
        <div class="md-form">
          <textarea type="text" id="postcode" name="postcode" class="md-textarea form-control" [(ngModel)]="newdata.postcode" mdbInput mdbValidate [validateSuccess]="false" #postcode="ngModel" required></textarea>
          <label for="postcode">Postcode</label>
          
        </div>
        <div class="md-form">
          <textarea type="text" id="notes" name="notes" class="md-textarea form-control" [(ngModel)]="newdata.notes" mdbInput mdbValidate [validateSuccess]="false" #notes="ngModel" required></textarea>
          <label for="status">Notes</label>
          
        </div>
        <div class="md-form">
          <textarea type="text" id="page" name="page" class="md-textarea form-control" [(ngModel)]="newdata.page" mdbInput mdbValidate [validateSuccess]="false" #page="ngModel" required></textarea>
          <label for="page">Page</label>
          
        </div>
        <div class="md-form">
          <input type="text" id="phone" name="phone" class="form-control" [(ngModel)]="newdata.phone" mdbInput mdbValidate [validateSuccess]="false" #phone="ngModel" required>
          <label for="phone">Phone Number</label>
         
        </div>
        <div class="md-form">
          <input type="text" id="phone2" name="phone2" class="form-control" [(ngModel)]="newdata.phone2" mdbInput mdbValidate [validateSuccess]="false" #phone2="ngModel" required>
          <label for="phone2">Hashtag/Keyword</label>
         
        </div>
        <div class="md-form">
          <input type="text" id="industry" name="industry" class="form-control" [(ngModel)]="newdata.industry" mdbInput mdbValidate [validateSuccess]="false" #industry="ngModel" required>
          <label for="industry">Industry Type</label>
         
        </div>
        <div class="md-form">
          <input type="text" id="date" name="date" class="form-control" [(ngModel)]="newdata.date" mdbInput mdbValidate [validateSuccess]="false" #date="ngModel" required>
          <label for="date">Date Found</label>
         
        </div>
        <div class="md-form">
          <input type="text" id="username" name="username" class="form-control" [(ngModel)]="newdata.username" mdbInput mdbValidate [validateSuccess]="false" #username="ngModel" required>
          <label for="username">User</label>
         
        </div>
        <div class="md-form">
          <input type="text" id="lead" name="lead" class="form-control" [(ngModel)]="newdata.lead" mdbInput mdbValidate [validateSuccess]="false" #lead="ngModel" required>
          <label for="lead">Lead Proof</label>
         
        </div>
  
  
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" mdbBtn color="white" class="black-text waves-light" aria-label="Close" (click)="modalRef.hide()"
        mdbWavesEffect>Close</button>
      <button type="button" mdbBtn color="red" class="relative waves-light" mdbWavesEffect (click)="onSave()">Save</button>
    </div>
  </div>
  