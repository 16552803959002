<div class="modal-content">
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100" id="myModalLabel">{{ heading }}</h4>
  </div>
  <div class="modal-body">
    <form #keywordForm="ngForm">
      <div class="md-form">
        <input type="text" id="id" name="id" class="form-control" [(ngModel)]="keyword.id" mdbInput mdbValidate [validateSuccess]="false" #id="ngModel" required>
        <label for="id">Id</label>
       
      </div>

      <div class="md-form">
        <input type="text" id="name" name="name" class="form-control" [(ngModel)]="keyword.name" mdbInput mdbValidate [validateSuccess]="false" #name="ngModel" required>
        <label for="name">Name</label>
        
      </div>

      <div class="md-form">
        <textarea type="text" id="description" name="description" class="md-textarea form-control" [(ngModel)]="keyword.description" mdbInput mdbValidate [validateSuccess]="false" #description="ngModel" required></textarea>
        <label for="description">Description</label>
       
      </div>
      <div class="md-form">
        <textarea type="text" id="exclude" name="exclude" class="md-textarea form-control" [(ngModel)]="keyword.exclude" mdbInput mdbValidate [validateSuccess]="false" #exclude="ngModel" required></textarea>
        <label for="exclude">Exclude - comma seperated (,)</label>
        
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" mdbBtn color="white" class="black-text waves-light" aria-label="Close" (click)="modalRef.hide()"
      mdbWavesEffect>Close</button>
    <button type="button" mdbBtn color="red" class="relative waves-light" mdbWavesEffect (click)="onSave()">Save</button>
  </div>
</div>
