<div class="container">
  <div class="row " >
    <div class="col-md-8">
      <img src="https://mdbootstrap.com/img/Marketing/other/logo/logo-mdb-angular-small.png" alt="Error 404" class="img-fluid">
      <h2 class="h2-responsive mt-3 mb-2">404. That's an error.</h2>
      <h4>The requested URL was not found on this server.</h4>
    </div>
    <div class="col-md-4">
      <img src="https://mdbootstrap.com/img/Others/grafika404-bf.png" alt="Error 404" class="img-fluid">
    </div>
  </div>
  </div>