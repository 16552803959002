import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Record } from '../models/records.model';
import { of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class RecordsService {

  constructor(private db: AngularFireDatabase, private afAuth: AngularFireAuth) { }

  get userId() {
    if (this.afAuth.auth.currentUser) {
      return this.afAuth.auth.currentUser.uid;
    }
  }

  add(record: Record, userId: string) {
    const records = this.db.list(`records/${userId}`);
    return records.push(record); 
  }

  addRecords(records: Record[]) {
    const userId = this.userId;

    if (userId) {
      records.forEach( (record: Record) => {
        this.db.list(`records/${userId}`).push(record);
      });
    }
  }

  get(userId: string) {
    return this.db.list(`records/${userId}`).snapshotChanges();
  }

  update(record: Record, userId: string) {
    const moverec = this.db.object(`records/${userId}/` + record.key);
    const duperec = this.db.object(`newdata/${userId}/` + record.key);
    
    // const allLeads = this.db.object(`leaderboard/OvRcxse9rFVw2dLIvsncN29cIZE2/` + record.hashtag + 'leadCount');
    // allLeads.update({leadCount: + 1})
   
    moverec.remove();
    const time1 = new Date().getUTCHours();
    const time2 = new Date().getUTCMinutes();
    const time3 = new Date().getUTCSeconds();
    const time4 = new Date().getUTCMilliseconds();
    const myDate = new Date().getDate();
    const myDate2 = new Date().getMonth() + 1;
    const myDate3 = new Date().getUTCFullYear();
    const fullDate = [myDate, myDate2, myDate3, time1, time2, time3, time4].join();
   
    duperec.update({id: record.id, notes: record.notes, name: record.name, description: record.description, phone: record.phone, page: record.page, address: record.address, phone2: record.phone2, postcode: record.postcode, industry: record.industry, status: record.status, date: record.date, hashtag: record.hashtag, username: record.username, lead: record.lead});  
    return of(this.db.object(`newdata/EQhRw2DvorTqvPaCX95mTdm50rf2/` + fullDate)
      .update({
        id: record.id,
        name: record.name,
        description: record.description,
        phone: record.phone,
        page: record.page,
        address: record.address,
        phone2: record.phone2, 
        postcode: record.postcode,
        industry: record.industry, 
        status: record.status,
        date: record.date,
        hashtag: record.hashtag,
        username: record.username,
        lead: record.lead,
        notes: record.notes,
        datesaved: record.datesaved
      }));
      
  }

  save(record: Record, userId: string) {
    const moverec = this.db.object(`records/${userId}/` + record.key);
    const duperec = this.db.object(`newdata/${userId}/` + record.key);
    
    // const allLeads = this.db.object(`leaderboard/OvRcxse9rFVw2dLIvsncN29cIZE2/` + record.hashtag + 'leadCount');
    // allLeads.update({leadCount: + 1})
    
    moverec.remove();
    const time1 = new Date().getUTCHours();
    const time2 = new Date().getUTCMinutes();
    const time3 = new Date().getUTCSeconds();
    const time4 = new Date().getUTCMilliseconds();
    const myDate = new Date().getDate();
    const myDate2 = new Date().getMonth() + 1;
    const myDate3 = new Date().getUTCFullYear();
    const fullDate = [myDate, myDate2, myDate3, time1, time2, time3, time4].join();
   
    duperec.update({id: record.id, name: record.name, notes: record.notes, description: record.description, phone: record.phone, page: record.page, address: record.address, phone2: record.phone2, postcode: record.postcode, industry: record.industry, status: record.status, date: record.date, hashtag: record.hashtag, username: record.username, lead: record.lead});  
    return of(this.db.object(`newdata/EQhRw2DvorTqvPaCX95mTdm50rf2/` + fullDate)
      .update({
        id: record.id,
        name: record.name,
        description: record.description,
        phone: record.phone,
        page: record.page,
        address: record.address,
        phone2: record.phone2, 
        postcode: record.postcode,
        industry: record.industry, 
        status: record.status,
        date: record.date,
        hashtag: record.hashtag,
        username: record.username,
        lead: record.lead,
        notes: record.notes,
        datesaved: record.datesaved
      }));
      
  }

  delete(record: Record, userId: string) {
    return this.db.object(`records/${userId}/` + record.key).remove();
  }
}
