<div class="z-depth-1">
 <table>
   
  <tbody>
    <tr *ngFor="let company of companies.slice(-1).reverse(); trackBy: trackByFn; let i = index;">
      <td> <button mdbBtn style="float: right;" class="redb white-text" (click)="onDeleteAll(company)">Delete All</button></td>
      <td><span style="margin-top: 20px; margin-bottom: 20px; font-size: 31px; float: left;" class="badge badge-info">{{ companies.length }}</span></td> 
     </tr>
   </tbody>
 </table>
  <table class="table table-striped table-responsive-md btn-table" mdbTable borderless="true">
    <thead style="background-color: transparent; color: #17a2b8 !important;">
      <tr>
        <!-- <th>Id</th> -->
        <!-- <th>Company Number</th> -->
        <th>Id</th>
        <th>Company Name</th>
        <th>Address</th>
        <th>SIC</th>
        <th>DATE</th>
        <th>CH</th>
        <th>Google</th>
        <th>Actions</th>
      </tr>
    </thead> 

    <tbody>
      <tr *ngFor="let company of companies; trackBy: trackByFn; let i = index;">
        
        
        <!-- <th scope="row">{{ record.id }}</th> -->  
        <!-- <td scope="row">{{ company.id }}</td> -->
        <td>{{ i + 1 }}</td>
        <td scope="row">{{ company.name }}</td>
        <td>{{ company.address }}</td>
        <th>{{ company.sic }}</th>
        <th>{{ company.date }}</th>
        <td><a href="{{ company.page }}" target="_blank" class="viewLink">View</a></td>
        <td><a href="{{ company.description }}" target="_blank" class="viewLink">View</a></td>
       
        <td>
          <!-- <a (click)="onEdit(record)" class="mr-2"><mdb-icon fas icon="edit"></mdb-icon></a> -->
          <a (click)="onDelete(company)" class="mr-2"><mdb-icon fas icon="trash"></mdb-icon></a>
          <a (click)="onSave(company)" class="mr-2"><mdb-icon fas icon="save"></mdb-icon></a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
