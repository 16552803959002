<div class="z-depth-1">
    <table class="table table-striped table-responsive-md btn-table" mdbTable borderless="true">
      <thead style="background-color: transparent">
        <tr>
          <!-- <th>Id</th> -->
          <!-- <th>Company Number</th> -->
          <th>Company Name</th>
          <th>Address</th>
          <th>SIC</th>
          <th>DATE FOUND</th>
          <th>CH</th>
          <th>Google</th>
          <th>Actions</th>
        </tr>
      </thead> 
      <!-- <div *ngIf="reddatas" class="mt-4 white">
        <app-companies-house-list2 [reddatas]="reddatas" (reddataEdited)="onRedDataEdit($event)" (reddataDeleted)="onRedDataDelete($event)" (reddataSaved)="onRedDataSave($event)"></app-companies-house-list2>
      </div> -->
      <tbody>
        <tr *ngFor="let reddata of reddatas.slice(1, 6).reverse(); trackBy: trackByFn">
          <!-- <th scope="row">{{ record.id }}</th> -->  
          <!-- <td scope="row">{{ company.id }}</td> -->
          <td scope="row">{{ reddata.name }}</td>
          <td>{{ reddata.address }}</td>
          <th>{{ reddata.sic }}</th>
          <th>{{ reddata.date }}</th>
          <td><a href="{{ reddata.page }}" target="_blank" class="viewLink">View</a></td>
          <td><a href="{{ reddata.description }}" target="_blank" class="viewLink">View</a></td>
         
          <td>
            <!-- <a (click)="onEdit(record)" class="mr-2"><mdb-icon fas icon="edit"></mdb-icon></a> -->
            <a (click)="onDelete(reddata)" class="mr-2"><mdb-icon fas icon="trash"></mdb-icon></a>
            <a (click)="onSave(reddata)" class="mr-2"><mdb-icon fas icon="save"></mdb-icon></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  