import { Component, OnInit, ViewChild } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Keyword } from '../../../keywords/models/keywords.model';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-keywords-modal',
  templateUrl: './keywords-modal.component.html',
  styleUrls: ['./keywords-modal.component.scss']
})
export class KeywordsModalComponent implements OnInit {
  @ViewChild('keywordForm', { static: true }) keywordForm: NgForm;

  heading: string;
  keyword: Keyword = {};

  keywordData: Subject<Keyword> = new Subject();

  constructor(public modalRef: MDBModalRef) { }

  ngOnInit() {
  }

  onSave() {
    if (this.keywordForm.valid) {
      this.keywordData.next(this.keyword);
    this.modalRef.hide();
    } else {
      const controls = this.keywordForm.controls;
      Object.keys(controls).forEach( controlName => controls[controlName].markAsTouched());
    }
  }

}
