import { Component, OnInit, ViewChild } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Record } from '../../../records/models/records.model';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-records-modal',
  templateUrl: './records-modal.component.html',
  styleUrls: ['./records-modal.component.scss']
})
export class RecordsModalComponent implements OnInit {
  @ViewChild('recordForm', { static: true }) recordForm: NgForm;

  heading: string;
  record: Record = {};

  recordData: Subject<Record> = new Subject();
  

  constructor(public modalRef: MDBModalRef) { } 

  ngOnInit() { 
  }

  onSave() {
    if (this.recordForm.valid) {
      this.recordData.next(this.record);
    this.modalRef.hide();
    } else {
      const controls = this.recordForm.controls;
      Object.keys(controls).forEach( controlName => controls[controlName].markAsTouched());
    }
  }

}
