// @ts-(expect-error|ignore)
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { RedData } from '../models/red-data.model';
import { of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class RedDatasService {

  constructor(private db: AngularFireDatabase, private afAuth: AngularFireAuth) { }

  get userId() {
    if (this.afAuth.auth.currentUser) {
      return this.afAuth.auth.currentUser.uid;
    }
  }

  add(reddata: RedData, userId: string) {
    const reddatas = this.db.list(`reddatas/${userId}`);
    return reddatas.push(reddata); 
  }

  addRedDatas(reddatas: RedData[]) {
    const userId = this.userId;

    if (userId) {
      reddatas.forEach( (reddata: RedData) => {
        this.db.list(`reddatas/${userId}`).push(reddata);
      });
    }
  }

  get(userId: string) {
    return this.db.list(`reddatas/${userId}`).snapshotChanges();
  }

  update(reddata: RedData, userId: string) {
    const moverec = this.db.object(`reddatas/${userId}/` + reddata.key);
    const duperec = this.db.object(`newdata/${userId}/` + reddata.key)
    moverec.remove(); 
    const time1 = new Date().getUTCHours();
    const time2 = new Date().getUTCMinutes();
    const time3 = new Date().getUTCSeconds();
    const time4 = new Date().getUTCMilliseconds();
    const myDate = new Date().getDate();
    const myDate2 = new Date().getMonth() + 1;
    const myDate3 = new Date().getUTCFullYear();
    const fullDate = [myDate, myDate2, myDate3, time1, time2, time3, time4].join();
    duperec.update({id: reddata.id, name: reddata.name, description: reddata.description, phone: reddata.phone, page: reddata.page, address: reddata.address, sic: reddata.sic, incdate: reddata.incdate, postcode: reddata.postcode, date: reddata.date, status: reddata.status, industry: reddata.sic, username: reddata.username, lead: reddata.lead});
    return of(this.db.object(`newdata/EQhRw2DvorTqvPaCX95mTdm50rf2/` + fullDate)
      .update({
        id: reddata.id,
        name: reddata.name,
        description: reddata.description,
        phone: reddata.phone,
        page: reddata.page, 
        address: reddata.address, 
        sic: reddata.sic,
        incdate: reddata.incdate,
        postcode: reddata.postcode,
        date: reddata.date,
        status: reddata.status,
        industry: reddata.sic,
        username: reddata.username,
        lead: reddata.lead,
        notes: reddata.notes,
        datesaved: reddata.datesaved
      }));

    
      
  }

  save(reddata: RedData, userId: string) {
    const moverec = this.db.object(`reddatas/${userId}/` + reddata.key);
    const duperec = this.db.object(`newdata/${userId}/` + reddata.key);
  
    moverec.remove();
    const time1 = new Date().getUTCHours();
    const time2 = new Date().getUTCMinutes();
    const time3 = new Date().getUTCSeconds();
    const time4 = new Date().getUTCMilliseconds();
    const myDate = new Date().getDate();
    const myDate2 = new Date().getMonth() + 1;
    const myDate3 = new Date().getUTCFullYear();
    const fullDate = [myDate, myDate2, myDate3, time1, time2, time3, time4].join();

    duperec.update({id: reddata.id, name: reddata.name, description: reddata.description, phone: reddata.phone, page: reddata.page, address: reddata.address, sic: reddata.sic, incdate: reddata.incdate, postcode: reddata.postcode, date: reddata.date, status: reddata.status, industry: reddata.sic, username: reddata.username, lead: reddata.lead});
      
    return of(this.db.object(`newdata/EQhRw2DvorTqvPaCX95mTdm50rf2/` + fullDate)
      .update({
        id: reddata.id,
        name: reddata.name,
        description: reddata.description,
        phone: reddata.phone,
        page: reddata.page,
        address: reddata.address, 
        sic: reddata.sic,
        incdate: reddata.incdate,
        postcode: reddata.postcode,
        date: reddata.date,
        status: reddata.status,
        industry: reddata.sic,
        username: reddata.username,
        lead: reddata.lead,
        notes: reddata.notes,
        datesaved: reddata.datesaved
      }));
      
  }

  delete(reddata: RedData, userId: string) {
    return this.db.object(`reddatas/${userId}/` + reddata.key).remove();
  }

  deleteAll(reddata: RedData, userId: string) {
    
    return this.db.object(`reddatas/${userId}/`).remove(), reddata.key;
  }
}
