<div class="modal-content">
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100" id="myModalLabel">{{ heading }}</h4>
  </div>
  <div class="modal-body">
    <form #bluedataForm="ngForm">
      <!-- <div class="md-form">
        <input type="text" id="id" name="id" class="form-control" [(ngModel)]="record.id" mdbInput mdbValidate [validateSuccess]="false" #id="ngModel" required>
        <label for="id">Id</label>
        <mdb-error *ngIf="id && id.invalid && (id.dirty || id.touched)">Id is required</mdb-error>
      </div> -->

      <div class="md-form">
        <input type="text" id="name" name="name" class="form-control" [(ngModel)]="bluedata.name" mdbInput mdbValidate [validateSuccess]="false" #name="ngModel" required>
        <label for="name">Name</label>
        <mdb-error *ngIf="name && name.invalid && (name.dirty || name.touched)">Name is required</mdb-error>
      </div>
      <div class="md-form">
        <input type="text" id="industry" name="industry" class="form-control" [(ngModel)]="bluedata.industry" mdbInput mdbValidate [validateSuccess]="false" #industry="ngModel" required>
        <label for="industry">Industry</label>
       </div>

      <div class="md-form">
        <input type="text" id="address" name="address" class="md-textarea form-control" [(ngModel)]="bluedata.address" mdbInput mdbValidate [validateSuccess]="false" #address="ngModel" required>
        <label for="address">Address</label>
        <mdb-error *ngIf="address && address.invalid && (address.dirty || address.touched)">Address is required</mdb-error>
      </div>
      <div class="md-form">
        <input type="text" id="postcode" name="postcode" class="form-control" [(ngModel)]="bluedata.postcode" mdbInput mdbValidate [validateSuccess]="false" #postcode="ngModel" required>
        <label for="postcode">Postcode</label>
       </div>
      <div class="md-form">
        <input type="text" id="phone" name="phone" class="form-control" [(ngModel)]="bluedata.phone" mdbInput mdbValidate [validateSuccess]="false" #phone="ngModel" required>
        <label for="phone">Phone Number</label>
        <!-- <mdb-error *ngIf="phone && phone.invalid && (phone.dirty || phone.touched)">Phone Number is required</mdb-error> -->
      </div>
      <!-- <div class="md-form" style="display: none;">
        <input type="text" id="hashAll" name="hashAll" class="form-control" [(ngModel)]="record.hashAll" mdbInput mdbValidate [validateSuccess]="false" #hashAll="ngModel" required>
        <label for="hashAll">HashAll</label>
       </div>
      <div class="md-form" style="display: none;">
        <input type="text" id="pulledCount" name="pulledCount" class="form-control" [(ngModel)]="record.pulledCount" mdbInput mdbValidate [validateSuccess]="false" #pulledCount="ngModel" required>
        <label for="pulledCount">PulledCount</label>
       
      </div> -->
      <!-- <div class="md-form">
        <input type="text" id="phone2" name="phone2" class="form-control"  mdbInput>
        <label for="phone2">Secondary Contact</label>
        
      </div> -->
      <div class="md-form">
        <input type="text" id="notes" name="notes" class="form-control" [(ngModel)]="bluedata.notes" mdbInput mdbValidate [validateSuccess]="false" #notes="ngModel" required>
        <label for="notes">Notes</label>
       </div>
       <div class="md-form">
        <input type="text" id="hashtag" name="hashtag" class="form-control" [(ngModel)]="bluedata.hashtag" mdbInput mdbValidate [validateSuccess]="false" #hashtag="ngModel" required>
        <label for="hashtag">Hashtag</label>
       </div>
       <div class="md-form">
        <input type="text" id="phone2" name="phone2" class="form-control" [(ngModel)]="bluedata.phone2" mdbInput mdbValidate [validateSuccess]="false" #hashtag="ngModel">
        <label for="phone2">Secondary Contact</label>
       </div>
       <div class="md-form" style="display:none">
        <input type="text" id="lead" name="lead" class="form-control" [(ngModel)]="bluedata.lead" mdbInput mdbValidate [validateSuccess]="false" #lead="ngModel" required>
        <label for="lead">Lead Proof</label>
       </div>
       <div class="md-form" style="display:none">
        <input type="text" id="page" name="page" class="form-control" [(ngModel)]="bluedata.page" mdbInput mdbValidate [validateSuccess]="false" #page="ngModel" required>
        <label for="lead">Page</label>
       </div>
      <div class="md-form">
        <input type="text" id="date" name="date" class="form-control" [(ngModel)]="bluedata.date" mdbInput mdbValidate [validateSuccess]="false" #date="ngModel" required>
        <label for="date">Date Found</label>
       
      </div>
      <div class="md-form">
        <input type="text" id="datesaved" name="datesaved" class="form-control" [(ngModel)]="bluedata.datesaved" mdbInput mdbValidate [validateSuccess]="false" #datesaved="ngModel" required>
        <label for="datesaved">Date Saved</label>
       
      </div>
      <!-- <div class="md-form" style="display: none;">
        <input type="text" id="leadCount" name="leadCount" class="form-control" [(ngModel)]="record.leadCount" mdbInput mdbValidate [validateSuccess]="false" #leadCount="ngModel" required>
        <label for="leadCount">leadCount</label>
       
      </div> -->
      <div class="md-form">
        <input type="text" id="username" name="username" class="form-control" [(ngModel)]="bluedata.username" mdbInput mdbValidate [validateSuccess]="false" #username="ngModel" required>
        <label for="username">Found By</label>
       </div>



    </form> 
  </div>
  <div class="modal-footer">
    <button type="button" mdbBtn color="white" class="black-text waves-light" aria-label="Close" (click)="modalRef.hide()"
      mdbWavesEffect>Close</button>
    <button type="button" mdbBtn color="red" class="relative waves-light" mdbWavesEffect (click)="onSave()">Save</button>
  </div>
</div>
