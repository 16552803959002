import { adminInitialState, AdminState } from './admin.state';
import { AdminActions, AdminActionTypes } from './admin.actions';

export function adminReducer(state = adminInitialState, action: AdminActions): AdminState {
  switch (action.type) {

    case AdminActionTypes.GET_USERS_LIST: {
      return Object.assign({}, state, {
        usersListLoading: true
      });
    }

    case AdminActionTypes.USERS_LIST_FETCHED: {
      return Object.assign({}, state, {
        usersList: action.payload.usersList,
        usersListLoading: false
      });
    }

    case AdminActionTypes.GET_USER_PROJECTS: {
      return Object.assign({}, state, {
        userProjectsLoading: true
      });
    }

    case AdminActionTypes.USERS_PROJECTS_LOADED: {
      return Object.assign({}, state, {
        userProjects: {...state.userProjects, [action.payload.uid]: action.payload.userProjects},
        userProjectsLoading: false
      });
    }

    case AdminActionTypes.GET_USER_CUSTOMERS: {
      return Object.assign({}, state, {
        userCustomersLoading: true
      });
    }

    case AdminActionTypes.USERS_CUSTOMERS_LOADED: {
      return Object.assign({}, state, {
        userCustomers: {...state.userCustomers, [action.payload.uid]: action.payload.userCustomers},
        userCustomersLoading: false
      });
    }

    case AdminActionTypes.GET_USER_RECORDS: {
      return Object.assign({}, state, {
        userRecordsLoading: true
      });
    }

    case AdminActionTypes.GET_USER_BLUEDATA: {
      return Object.assign({}, state, {
        userBlueDataLoading: true
      });
    }

    case AdminActionTypes.GET_USER_ORANGEDATA: {
      return Object.assign({}, state, {
        userOrangeDataLoading: true
      });
    }

    case AdminActionTypes.GET_USER_BDIL: {
      return Object.assign({}, state, {
        userBdilLoading: true
      });
    }

    case AdminActionTypes.GET_USER_LEADS: {
      return Object.assign({}, state, {
        userLeadsLoading: true
      });
    }

    case AdminActionTypes.USERS_RECORDS_LOADED: {
      return Object.assign({}, state, {
        userRecords: {...state.userRecords, [action.payload.uid]: action.payload.userRecords},
        userRecordsLoading: false
      });
    }

    case AdminActionTypes.USERS_BLUEDATA_LOADED: {
      return Object.assign({}, state, {
        userBlueData: {...state.userBlueData, [action.payload.uid]: action.payload.userBlueData},
        userBlueDataLoading: false
      });
    }

    case AdminActionTypes.USERS_ORANGEDATA_LOADED: {
      return Object.assign({}, state, {
        userOrangeData: {...state.userOrangeData, [action.payload.uid]: action.payload.userOrangeData},
        userOrangeDataLoading: false
      });
    }

    case AdminActionTypes.USERS_BDIL_LOADED: {
      return Object.assign({}, state, {
        userBdil: {...state.userBdil, [action.payload.uid]: action.payload.userBdil},
        userBdilLoading: false
      });
    }

    case AdminActionTypes.USERS_LEADS_LOADED: {
      return Object.assign({}, state, {
        userLeads: {...state.userLeads, [action.payload.uid]: action.payload.userLeads},
        userLeadsLoading: false
      });
    }

    case AdminActionTypes.USERS_NEWDATA_LOADED: {
      return Object.assign({}, state, {
        userNewData: {...state.userNewData, [action.payload.uid]: action.payload.userNewData},
        userNewDataLoading: false
      });
    }

    case AdminActionTypes.USERS_USERDATA_LOADED: {
      return Object.assign({}, state, {
        userUserData: {...state.userUserData, [action.payload.uid]: action.payload.userUserData},
        userUserDataLoading: false
      });
    }

    case AdminActionTypes.ADMIN_ERROR: {
      return Object.assign({}, state, {
        error: action.payload.error
      });
    }

    default:
      return state;
  }
}
