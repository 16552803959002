import { createSelector } from '@ngrx/store';
import { AppState } from '../../reducers/index';

export const getAdminState = (state: AppState) => state.admin;

export const getUsersList = createSelector(
  getAdminState,
  admin => admin.usersList
);

export const getUsersListLoading = createSelector(
  getAdminState,
  admin => admin.usersListLoading
);

export const getSelectedUser = createSelector(
  getUsersList,
  (usersList: any, uid: string) => usersList.filter( (user: any) => user.uid === uid)[0]
);

export const getUserProjects = createSelector(
  getAdminState,
  (admin: any, uid: string) => {
    if (admin.userProjects.hasOwnProperty(uid)) {
      return admin.userProjects[uid];
    } else {
      return null;
    }
  }
);

export const getUserCustomers = createSelector(
  getAdminState,
  (admin: any, uid: string) => {
    if (admin.userCustomers.hasOwnProperty(uid)) {
      return admin.userCustomers[uid];
    } else {
      return null;
    }
  }
);

export const getUserKeywords = createSelector(
  getAdminState,
  (admin: any, uid: string) => {
    if (admin.userKeywords.hasOwnProperty(uid)) {
      return admin.userKeywords[uid];
    } else {
      return null;
    }
  }
);

export const getUserRecords = createSelector(
  getAdminState,
  (admin: any, uid: string) => {
    if (admin.userRecords.hasOwnProperty(uid)) {
      return admin.userRecords[uid];
    } else {
      return null;
    }
  }
);

export const getUserBlueData = createSelector(
  getAdminState,
  (admin: any, uid: string) => {
    if (admin.userBlueData.hasOwnProperty(uid)) {
      return admin.userBlueData[uid];
    } else {
      return null;
    }
  }
);

export const getUserOrangeData = createSelector(
  getAdminState,
  (admin: any, uid: string) => {
    if (admin.userOrangeData.hasOwnProperty(uid)) {
      return admin.userOrangeData[uid];
    } else {
      return null;
    }
  }
);

export const getUserBdil = createSelector(
  getAdminState,
  (admin: any, uid: string) => {
    if (admin.userBdil.hasOwnProperty(uid)) {
      return admin.userBdil[uid];
    } else {
      return null;
    }
  }
);

export const getUserLeads = createSelector(
  getAdminState,
  (admin: any, uid: string) => {
    if (admin.userLeads.hasOwnProperty(uid)) {
      return admin.userLeads[uid];
    } else {
      return null;
    }
  }
);

export const getUserCompanies = createSelector(
  getAdminState,
  (admin: any, uid: string) => {
    if (admin.userCompanies.hasOwnProperty(uid)) {
      return admin.userCompanies[uid];
    } else {
      return null;
    }
  }
);

export const getUserRedDatas = createSelector(
  getAdminState,
  (admin: any, uid: string) => {
    if (admin.userRedDatas.hasOwnProperty(uid)) {
      return admin.userRedDatas[uid];
    } else {
      return null;
    }
  }
);

export const getUserNewData = createSelector(
  getAdminState,
  (admin: any, uid: string) => {
    if (admin.userNewData.hasOwnProperty(uid)) {
      return admin.userNewData[uid];
    } else {
      return null;
    }
  }
);

export const getUserUserData = createSelector(
  getAdminState,
  (admin: any, uid: string) => {
    if (admin.userUserData.hasOwnProperty(uid)) {
      return admin.userUserData[uid];
    } else {
      return null;
    }
  }
);

export const getUserProjectsLoading = createSelector(
  getAdminState,
  admin => admin.userProjectsLoading
);

export const getUserCustomersLoading = createSelector(
  getAdminState,
  admin => admin.userCustomersLoading
);

export const getUserCompaniesLoading = createSelector(
  getAdminState,
  admin => admin.userCompaniesLoading
);

export const getUserRedDatasLoading = createSelector(
  getAdminState,
  admin => admin.userRedDatasLoading
);

export const getUserKeywordsLoading = createSelector(
  getAdminState,
  admin => admin.userKeywordsLoading
);

export const getUserRecordsLoading = createSelector(
  getAdminState,
  admin => admin.userRecordsLoading
);

export const getUserBlueDataLoading = createSelector(
  getAdminState,
  admin => admin.userBlueDataLoading
);

export const getUserOrangeDataLoading = createSelector(
  getAdminState,
  admin => admin.userOrangeDataLoading
);


export const getUserBdilLoading = createSelector(
  getAdminState,
  admin => admin.userBdilLoading
);

export const getUserLeadsLoading = createSelector(
  getAdminState,
  admin => admin.userLeadsLoading
);

export const getUserNewDataLoading = createSelector(
  getAdminState,
  admin => admin.userNewDataLoading
);

export const getUserUserDataLoading = createSelector(
  getAdminState,
  admin => admin.userUserDataLoading
);
