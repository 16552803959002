<div class="z-depth-1">
  <tr *ngFor="let newdatas of newdata.slice(1, 2).reverse(); trackBy: trackByFn; let i = index;">
    <td> <button mdbBtn style="float: right;" class="redb white-text" (click)="onDeleteAll(newdata)">Delete All</button> </td> 
    
    <td><span style="margin-bottom: 6px; margin-top: 12px; margin-left: 10px; font-size: 31px; float: left;" class="badge badge-info">{{ newdata.length }}</span></td> 
    
  
  </tr>
    <table class="table table-striped table-responsive-md btn-table" style="text-align: center;" mdbTable borderless="true">
      <thead style="background-color: transparent; text-align: left;">
        <tr>
          <!-- <th>Id</th> -->
          <!-- <th>Lead Count</th> -->
          <th>Company Name</th>
          <th>Address</th>
          <th>Postcode</th>
          <th>Notes</th>
         
          
          <th>Phone Number</th>
          <th>Phone Number 2</th>
          <th>Hashtag/Keyword</th>
          <th>industry Type</th>
          <th>Date Found</th>
          <th>Date Saved</th>
          <th>Lead Proof</th>
          <th>Page</th>
          <th>User</th>
          <th>Actions</th>
         
        </tr> 
      </thead>
  
      <tbody>
        <tr *ngFor="let newdata of newdata; trackBy: trackByFn; let i = index;" style="text-align: left;">
          <!-- <td>{{ i + 1 }}</td> -->
          <td scope="row">{{ newdata.name }}</td>
          <td>{{ newdata.address }}</td>
          <td>{{ newdata.postcode }}</td>
          <td>{{ newdata.notes }}</td>
          
          <!-- <td><a href="{{ newdata.description }}" target="_blank">View Post</a></td> -->
          <td>{{ newdata.phone }}</td> 
          <td>{{ newdata.phone2 }}</td>
          <td>{{ newdata.hashtag }}</td>
          <td>{{ newdata.industry }}</td>
          <td>{{ newdata.date }}</td>
          <td>{{ newdata.datesaved }}</td>
          <td><a href="{{ newdata.lead }}" target="_blank">View Lead Proof</a></td>
          <td><a href="{{ newdata.page }}" target="_blank">View Page</a></td>
          <td>{{ newdata.username }}</td>
          <td>
            <a (click)="onEdit(newdata)" class="mr-2"><mdb-icon fas icon="edit"></mdb-icon></a>
            <a (click)="onDelete(newdata)" class="mr-2"><mdb-icon fas icon="trash"></mdb-icon></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  