import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Company } from '../../../companies-house/models/companies-house.model';


@Component({
  selector: 'app-companies-house-list',
  templateUrl: './companies-house-list.component.html',
  styleUrls: ['./companies-house-list.component.scss']
})
export class CompaniesListComponent implements OnInit {
  @Input() companies: Company[];
  @Output() companyDeleted = new EventEmitter<Company>();
  @Output() companyDeletedAll = new EventEmitter<Company>();
  @Output() companyEdited = new EventEmitter<Company>();
  @Output() companySaved = new EventEmitter<Company>();
  

  

  constructor() { }

  ngOnInit() {
  }

  onEdit(company: Company) {
    this.companyEdited.emit(company);
  }

  onDelete(company: Company) {
    this.companyDeleted.emit(company); 
  }

  onDeleteAll(company: Company) {
    this.companyDeletedAll.emit(company); 
  }

  onSave(company: Company) {
    this.companySaved.emit(company);
  }

  trackByFn(index: any) {
    return index;
  }

  

}
