import { Component, OnInit, ViewChild } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { UserData } from '../../../user-data/models/user-data.model';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-userdata-modal',
  templateUrl: './userdata-modal.component.html',
  styleUrls: ['./userdata-modal.component.scss']
})
export class UserdataModalComponent implements OnInit {
  @ViewChild('userdataForm', {static: true}) userdataForm: NgForm;

  heading: string; 
  userdata: UserData = {};

  userdataData: Subject<UserData> = new Subject();

  constructor(public modalRef: MDBModalRef) { }

  ngOnInit() { 
 
  }

  onSave() {
    if (this.userdataForm.valid) {
      this.userdataData.next(this.userdata);
      this.modalRef.hide();
    } else {
      const controls = this.userdataForm.controls;
      Object.keys(controls).forEach( controlName => controls[controlName].markAsTouched());
    }
  }

}
