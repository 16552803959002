import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserData } from '../../../user-data/models/user-data.model';

@Component({
  selector: 'app-user-data-list',
  templateUrl: './user-data-list.component.html',
  styleUrls: ['./user-data-list.component.scss']
})
export class UserDataListComponent implements OnInit {
  @Input() userdata: UserData[];
  @Output() userdataDeleted = new EventEmitter<UserData>();
  @Output() userdataEdited = new EventEmitter<UserData>();
  @Output() userdataSaved = new EventEmitter<UserData>();

  constructor() { }

  ngOnInit() { 
  }

  onEdit(userdata: UserData) {
    this.userdataEdited.emit(userdata);
  }

  onDelete(userdata: UserData) {
    this.userdataDeleted.emit(userdata);
  }

  onSave(userdata: UserData) {
    this.userdataSaved.emit(userdata);
  }

  trackByFn(index: any) {
    return index;  
  }
}
